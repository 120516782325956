import React, { useRef, useState } from "react";
import { FaRegWindowClose } from "react-icons/fa";
import { motion, AnimatePresence } from "framer-motion";
import { useForm } from "react-hook-form";

import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import Erreurs from "../erreurs/Erreurs";
import Spinner from "../Spinner/Spinner";
import MainClass from "../../utils/classes/MainClass";
const RejectDossierModal = ({ isOpen, setIsOpen,id }) => {
  const [motif, setMotif] = useState("Motif 1");
  const [nouveaumotif, setNouveaumotif] = useState('');
  const [erreurs,setErreurs] = useState([]);
  const [isLoading,setIsLoading] = useState(false);
  const { register, handleSubmit, formState: { errors } } = useForm();
  const modalRef = useRef();
  const api = new MainClass(); 
  const closeModal = (e) => {
    if (modalRef.current === e.target) {
      setIsOpen(false);
    }
  };
  const navigate = useNavigate();
  const submitMotif = async (e)=>{
     //e.preventDefault();
     setIsLoading(true);setErreurs([]);
      var formData = new FormData();
      // formData.append('motif',motif);
      if(nouveaumotif.length > 0 )
         formData.append('motif',nouveaumotif);
      else formData.append('motif',motif);   
      formData.append("id",id);
      const { status,errors } = await api.apiData("post","/dossier/motifRejet",formData);
      setIsLoading(false)
      if( status === 200 ){
        toast.success("Rejet effectué avec succès");
        navigate("/dossier/liste");
      }
      else{
        setErreurs(erreurs);
      }
      
  }

  return (
    <AnimatePresence>
      {isOpen ? (
        <div className="rejection-modal modal-component" onClick={closeModal} ref={modalRef}>
          {isLoading && <Spinner />}
          <motion.div
            initial={{ opacity: 0, top: "30%" }}
            animate={{ opacity: 1, top: "50%" }}
            transition={{ duration: 0.2 }}
            exit={{ opacity: 0, top: "10%" }}
            className="modal">
            <div className="close" onClick={() => setIsOpen(false)}>
              <FaRegWindowClose id="close-icon" />
            </div>
            <h4>Motif de rejet</h4>
            <form onSubmit={handleSubmit(submitMotif)}>
            <Erreurs validation = {erreurs} />
              <div>
                <label>
                  Choisissez le motif de rejet
                  <select name="motif" value={motif} 
                 {...register('motif', {
                    onChange: (e) => {
                      setMotif(e.target.value);setNouveaumotif('');
                    },
                    validate: (value) => value != 0 || value == ''
                  },
                  )}
                 
                  >
                    <option value={0}>Choisissez le motif de rejet</option>
                    <option value="Numéro de chassis incorrect.">Dossier déjà traité.</option>
                    <option value="Fond de dossier Incomplet.">Fond de dossier Incomplet.</option>
                   
                    <option value="">Autre</option>
                  </select>
                  {errors.motif && (
                             <span className="error-msg">Veuillez choisir le motif.</span>
                          )}
                </label>
              </div>
              {motif === "" && (
                <div>
                  <label>
                    Ou saisissez un nouveau motif de rejet
                    <textarea onChange={(e) => setNouveaumotif(e.target.value)}
                      name="nouveaumotif"
                      id="nouveaumotif"
                      cols={30} 
                      rows={10}
                      placeholder= {motif === "" ? "Saissisez le motif":''}
                      disabled={motif !== ""}
                      autoFocus={motif !== ""}
                      value={nouveaumotif}
                    />
                  </label>
                </div>
                )
              }
              <div className="buttons">
                <button className="reject-btn" disabled={isLoading}>Rejeter</button>
              </div>
            </form>
          </motion.div>
        </div>
      ) : null}
    </AnimatePresence>
  );
};

export default RejectDossierModal;
