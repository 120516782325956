import React, { useRef, useState, useEffect, useContext } from "react";
import { FaRegWindowClose } from "react-icons/fa";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { motion, AnimatePresence } from "framer-motion";
import { set, useForm } from "react-hook-form";
import Erreurs from "../erreurs/Erreurs";
import toast from "react-hot-toast";
import Spinner from "../Spinner/Spinner";
import { ElementContext, LocaliteContext } from "../../utils/context/contex";
import { objecttoFormData } from "../../utils/helper/functions";
import MainClass from "../../utils/classes/MainClass";

const UpdateUtilisateurModal = ({ isOpen, setIsOpen,setIsLoading,isUpdate,setUpdate,data }) => {

  const [passwordVisible, setPasswordVisible] = useState(false);
  const passPattern =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*\\W)[a-zA-Z0-9\\S]{6,}$/;
  if( typeof data.role !== 'undefined' && data?.role){
    data.role = data.role.toLowerCase();
  }
  const api = new MainClass();
  const { localites } = useContext(LocaliteContext);
  const { elements } = useContext(ElementContext);
  const { centres } = elements?elements:'';
  
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [user, setUser] = useState(data)
  const [erreurs, setErreurs] = useState([]);

  const modalRef = useRef();
  const closeModal = (e) => {
    if (modalRef.current === e.target) {
      setIsOpen(false);
    }
  };

  const handleInput = (e) => {
    setUser({...user,[e.target.name]: e.target.value,});
  };

  useEffect(() => {
      setUser(data);
  }, [data.id]);
  const onUserSubmit = async () => {
    setIsLoading(true);setErreurs([]);
    let data = user;
    delete data.centre;
    delete data.prefecture_id;
    data = objecttoFormData(user);  
    const { status , errors,UpdatedUser } = await api.apiData("post","/user/update",data);
    setIsLoading(false);
    if( status === 200 ){
      setUpdate(!isUpdate);
      toast.success("Mise à jour effectuée avec succès.")
      setIsOpen(false);
    }else {
      setErreurs(errors);
    } 
  }

  return (
    <AnimatePresence>
      {isOpen ? (
        <div
          className="modal-component gestion-utilisateur-modal"
          onClick={closeModal}
          ref={modalRef}
        >
          <motion.div
            initial={{ opacity: 0, top: "30%" }}
            animate={{ opacity: 1, top: "50%" }}
            transition={{ duration: 0.2 }}
            exit={{ opacity: 0, top: "10%" }}
            className="modal"
          >
            <div className="close" onClick={() => setIsOpen(false)}>
              <FaRegWindowClose id="close-icon" />
            </div>
            <h4>Modifier l'utilisateur</h4>

            {user &&(
               <form onSubmit={handleSubmit(onUserSubmit)}>
          
              <div className="input-group">
                <label> 
                  Prénom <span className="obligatoire"> *</span>
                  <input
                    type="text"
                    name="prenom"
                    id="prenom"
                    placeholder="Prenom"
                    autoComplete="false"
                    value={user?.prenom}
                    {...register("prenom", {
                      onChange: (e) => {
                        handleInput(e);
                      },
                      required: true,
                      minLength: 3,
                    })}
                  />
                  {errors.prenom && errors.prenom.type === "required" && (
                    <span role="alert" className="error-msg">
                      Champ obligatoire.
                    </span>
                  )}
                  {errors.prenom && errors.prenom.type === "minLength" && (
                    <span role="alert" className="error-msg">
                      Le nombre minimum de caractères est 3.
                    </span>
                  )}
                </label>

                <label>
                  Nom <span className="obligatoire"> *</span>
                  <input
                    type="text"
                    name="nom"
                    id="nom"
                    placeholder="Nom"
                    autoComplete="false"
                    value={user?.nom}
                    {...register("nom", {
                      onChange: (e) => {
                        handleInput(e);
                      },
                      required: true,
                      minLength: 3,
                    })}
                  />
                  {errors.nom && errors.nom.type === "required" && (
                    <span role="alert" className="error-msg">
                      Champ obligatoire.
                    </span>
                  )}
                  {errors.nom && errors.nom.type === "minLength" && (
                    <span role="alert" className="error-msg">
                      Le nombre minimum de caractères est 3.
                    </span>
                  )}
                </label>
              </div>
              <div className="input-group">
                <label>
                    Téléphone <span className="obligatoire"> *</span>
                    <input
                    type="text"
                    name="telephone"
                    id="telephone"
                    placeholder='ex:620 000 000'
                    autoComplete="false"
                    value={user?.telephone}
                     {...register("telephone", {
                      onChange: (e) => {
                        handleInput(e);
                      },
                      minLength: 9,
                      pattern: /6[0-9]{8}$/g,
                     })}
                    />
                  {errors.telephone && errors.telephone?.type === "required" && (
                     <span className="error-msg">Ce champ est obligatoire.</span>
                   )}
                   {errors.telephone && errors.telephone?.type === "minLength" && (
                     <span className="error-msg">Le numéro de téléphone ne peut pas être inférieur à 9 caractères.</span>
                   )}
                   {errors.telephone && errors.telephone?.type === "pattern" && (
                      <span className="error-msg">Le Format du numéro de téléphone est 620 000 000.</span>
                  )}
                </label>
                <label> Email <span className='obligatoire'>*</span>
                    <input type="email" placeholder='Email' name='email'
                     value={user.email}
                     {...register('email', {
                        onChange: (e) => {
                             handleInput(e)
                        },
                      required:true,
                      pattern: /\S+@\S+\.\S+/g,
                    },
                    )}
                    />
                 {errors.email && errors.email?.type === "required" && (
                    <span className="error-msg">L'email est obligatoire.</span>
                 )}
                 {errors.email && errors.email?.type === "pattern" && (
                    <span className="error-msg">Le Format du numéro de email est example@example.com.</span>
                )}
                </label>
              </div>
               <div className="input-group">
                <label>Localité <span className='obligatoire'>*</span>
                     <select value={user.prefecture_id} name= "prefecture_id"
                       {...register('prefecture_id', {
                        onChange: (e) => {
                            handleInput(e)
                        },
                        validate: (value) => value != "" 
                        },)}
                      >
                        <option value="">-</option>
                       {localites?.length > 0 && 
                        localites.map((localite) => {
                           return(<option value={localite.prefecture_id} key={localite._id}>{localite.nom}</option>)
                        })
                       }
                     </select>
                     {errors.prefecture_id && (
                          <span className="error-msg">La localité est obligatoire.</span>
                     )}
                  </label>
                  <label>Centre de Traitement <span className='obligatoire'>*</span>
                     <select value={user.centre_id} name= "centre_id"
                       {...register('centre_id', {
                        onChange: (e) => {
                            handleInput(e)
                        },
                        validate: (value) => value != "" 
                        },)}
                      >
                        <option value="">-</option>
                       {centres?.length > 0 && 
                        centres.filter((p) => p.localite_id == user.prefecture_id ).map((centre) => {
                           return(<option value={centre.centre_id} key={centre._id}>{centre.nom}</option>)
                        })
                       }
                     </select>
                     {errors.centre_id && (
                          <span className="error-msg">Le centre  est obligatoire.</span>
                     )}
                  </label>
                
              </div>
               <div className="input-group">
                 <label>Rôle <span className='obligatoire'>*</span>
                    <select name='role'
                     value={user.role ? user.role.toLowerCase():''}
                     {...register('role', {
                        onChange: (e) => {
                            handleInput(e)
                        },
                        validate: (value) => value != "" 
                        },)}
                    >
                        <option value="" key="0">-</option>
                        <option value="admin" key="admin">Admin</option>
                        <option value="management" key="management">Management</option>
                        <option value="service client" key="service client">Service client</option>
                        <option value="superviseur" key="superviseur">Superviseur</option>
                        <option value="edition" key="edition">Edition</option>
                        <option value="service impression" key="service impression">Service impression</option>
                    </select>
                    {errors.role && (
                             <span className="error-msg">Le rôle est obligatoire.</span>
                     )}
                 </label>
                 <label className="password">
                   Mot de passe
                   <div className="password-field">
                    <input 
                      type={passwordVisible ? "text" : "password"} autoComplete='false'
                      name="password"
                      id="password"
                      key="password"
                      placeholder="Mot de passe"
                      value={ user?.password ? user?.password: ""}
                      {...register('password', {
                        onChange: (e) => {
                            handleInput(e)
                        },
                        required:false,
                        pattern:  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{6,30}$/,
                       },)}
                     />
                    <div className="eye-icon" onClick={() => setPasswordVisible(!passwordVisible)}>
                      {passwordVisible ? <AiFillEyeInvisible /> : <AiFillEye />}
                    </div>
                   </div>
                   {errors.password && errors.password?.type === "pattern" && (
                       <span className="error-msg">Le mot de passe doit être une chaine de caractère de six (6) dont aumoin un majascule,un miniscule,un nombre et un caractère spécial.</span>
                   )}
                  </label>
                
               </div>
              <Erreurs validation={erreurs} />
              <button>Enregistrer</button>
               </form>
              )
            }
          </motion.div>
        </div>
      ) : null}
    </AnimatePresence>
  );
};

export default UpdateUtilisateurModal;
