import React, { useEffect, useState } from "react";
import Erreurs from "../../components/erreurs/Erreurs";
import logo from "../../assets/logo-dsd.png";

import { useForm } from "react-hook-form";
import { Helmet } from "react-helmet-async";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useContext } from "react";
import { UserContext } from "../../utils/context/contex";
import MainClass from "../../utils/classes/MainClass";
import toast from "react-hot-toast";
import { objecttoFormData } from "../../utils/helper/functions";

const Login = () => {
  const { id } = useParams();
  const [userInfo , setUserInfo ] = useState({
    id:'',
    password:'',
    new_password: '',
    confirm_password: ''
  });
  const { register, handleSubmit, watch,formState: { errors } } = useForm({});
 
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { user,setUser } = useContext(UserContext);
  
  const api = new MainClass();
  const [ erreurs,setErreurs ] = useState(null);
 

  
  //Avoir les Inputs de l'utilisateur
   const handleInput = (e) => {
    setUserInfo({...userInfo,[e.target.name]:e.target.value});
   }
   const submitForm = async (e) => {
      setIsLoading(true); setErreurs([]);
      var formata = objecttoFormData(userInfo);
      const { status ,errors } = await api.apiData("post",`/user/initPassword/${id}`,formata);
      if( status === 200 ){
        toast.success("Mot de passe changé avec succès.");
        navigate("/");
      }
      else {
        setErreurs(errors);
      }
      setIsLoading(false);
   }
   useEffect(() => {
      return () => {
        if( id.length < 24 || id.length > 24){
          toast.error("Mauvais Format d'identifiant")
          navigate("/");
        }
      }
   },[id])
 
  return (
    <div className="login-page">
      <Helmet>
        <title>DSDGUINEE - Connexion</title>
      </Helmet>
    
      <div className="head">
        <div className="header-1">
          <p>République de Guinée</p>
          <p><span>Travail-</span><span>Justice-</span><span>Solidarité</span></p>
        </div>
      
      </div>
      <div className="form">
        <div className="logo">
          <div className="image">
            <img src={logo} alt="" />
          </div>
        </div>
        <form onSubmit={handleSubmit(submitForm)}>
        <h3>Changer Votre Mot Passe</h3>
          <div>  
            <label className="password">
               <input type="password" name="password"
                value={userInfo.password} 
                  placeholder="Mot de passe actuel"
                 {...register('password', {
                  onChange: (e) => {
                      handleInput(e)
                  },
                  required:true,min:6
                 })}  
                 autoComplete ="off"
               />
              {errors.password && errors.password?.type === "required" && (
                  <span className="error-msg">Le mot de passe est obligatoire.</span>
               )}
               {errors.password && errors.password?.type === "min" && (
                  <span className="error-msg">Le mot de passe doit contenir au moin six (6) caractères .</span>
               )}
            </label>
          </div>  
          <div>  
            <label className="password">
               <input type="password" name="new_password"
                value={userInfo?.new_password} 
                onCopy={(e)=>{
                  e.preventDefault()
                  return false;
                }}
                onCut={(e)=>{
                  e.preventDefault()
                  return false;
                }}
                onPaste={(e)=>{
                  e.preventDefault()
                  return false;
                }}
                  placeholder="Nouveau mot de passe"
                 {...register('new_password', {
                  onChange: (e) => {
                      handleInput(e)
                  },
                  required:true,
                  pattern:  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{6,30}$/,
                 })}  
                 autoComplete ="off"
               />
              {errors.new_password && errors.new_password?.type === "required" && (
                  <span className="error-msg">Le mot de passe est obligatoire.</span>
               )}
              {errors.new_password && errors.new_password?.type === "pattern" && (
                       <span className="error-msg">Le mot de passe doit être une chaine de caractère de six (6) dont aumoin un majascule,un miniscule,un nombre et un caractère spécial.</span>
               )}
            </label>
          </div>  
          <div>  
            <label className="password">
               <input type="password" name="confirm_password"
                value={userInfo?.confirm_password} 
                onCopy={(e)=>{
                  e.preventDefault()
                  return false;
                }}
                onCut={(e)=>{
                  e.preventDefault()
                  return false;
                }}
                onPaste={(e)=>{
                  e.preventDefault()
                  return false;
                }}
                  placeholder="Confirmation"
                 {...register('confirm_password', {
                  onChange: (e) => {
                      handleInput(e)
                  },
                  required:true,
                  validate: (val) => {
                    if ( userInfo.new_password !== val) {
                      return "Les deux mots de passe doivent être identiques.";
                    }
                  }

                 })}  
                 autoComplete ="off"
               />
              {errors.confirm_password && errors.confirm_password?.type === "required" && (
                  <span className="error-msg">Le mot de passe de confirmation est obligatoire.</span>
               )}
           {(errors.confirm_password && errors.confirm_password?.type !== "required" ) && (
                <span className="error-msg">Les deux mots de passes doivent être identiques</span>
           )}
            </label>
          </div> 
            <Erreurs validation={erreurs}/>
            <button type="submit" disabled={isLoading}> {isLoading ? "...Patientez":"Valider"}</button>
        </form>
      
      </div>
      {/* <div className="motifs">
        <img src={star} alt="" className="motif star" />
        <img src={ellipse1} alt="" className="motif ellipse1" />
        <img src={ellipse2} alt="" className="motif ellipse2" />
        <img src={polygon1} alt="" className="motif polygon1" />
        <img src={line1} alt="" className="motif line1" />
        <img src={rectangle1} alt="" className="motif rectangle1" />
      </div> */}
      <div className="footer">
        <p>DSD-GUINEE &copy;2022. Tous droits réservés</p>
      </div>
    </div>
  );
};

export default Login;
