
//validation step 2
export function validateDate(date){
  var regex=new RegExp("([0-9]{4}[-](0[1-9]|1[0-2])[-]([0-2]{1}[0-9]{1}|3[0-1]{1})|([0-2]{1}[0-9]{1}|3[0-1]{1})[-](0[1-9]|1[0-2])[-][0-9]{4})");
  var dateOk=regex.test(date);
  if(dateOk){
      return true
  }else{
      return false;
  }
}
export function checkValid(fields)
  {
      return fields.idpays != 0 && fields.genre != 0
      && fields.type !== 0 && fields.marques != 0
      && fields.model != 0 && fields.carrosserie != 0
      && fields.annee >= 1900 && fields.energie != 0
      && fields.numChassie.length <= 17 && parseInt(fields.nbPorte) > 0 
      && parseInt(fields.nbPlaceAssise) > 0 && parseInt(fields.nbPlaceAssise) 
      && parseInt(fields.nbPlaceDebout) > 0 && validateDate(fields.dateP)
      && fields.cylindre !== 0 && fields.pa !== 0
      && parseInt(fields.kilometrage) >= 0 ;
  }
  export function objecttoFormData(elements){
    var formData = new FormData(); 
      Object.keys(elements).forEach((key) => {
        if( typeof elements[key] === 'string' || typeof elements[key] === 'boolean' || typeof elements[key] === 'number')
          formData.append(key, elements[key])
     
        else if(typeof elements[key] === 'object' ){
         
           if(Array.isArray(elements[key])){
              formData.append(key,JSON.stringify(elements[key]))
           }
           else{
            formData.append(key,elements[key])
           }
          
        }   
      });
      return formData;
  } 
  export const FirstUpperCase = (caracteres) => {
    if(caracteres?.length === 0)
       return caracteres.toUpperCase();
    else {
     var splitStr = caracteres?.toLowerCase().split(' ') ;
     for (var i = 0; i < splitStr?.length; i++) {
         // You do not need to check if i is larger than splitStr length, as your for does that for you
         // Assign it back to the array
         splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
     }
     return splitStr?.join(' '); 
    }     
  }
 export function isObjectEmpty(objc){
  return Object.keys(objc).length === 0 && objc.constructor === Object
 }
 export function makePassword(length){
    // var allc = "!@#$%^&*()_+~`|}{[]\:;?><,./-=0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ",
    // passgen = '';
    let pass = '';
    let str = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ' +
        'abcdefghijklmnopqrstuvwxyz0123456789@#$';
 
    for (let i = 1; i <= length; i++) {
        let char = Math.floor(Math.random()
            * str.length + 1);
 
        pass += str.charAt(char)
    }
    var specialChar = '@!$#'
    if( !pass.includes('@') && !pass.includes('!') && !pass.includes('$') ){
      pass = pass + specialChar [randomIntFromInterval(0,3)];
    }
    if(!containsUppercase(pass)){
       pass = pass + str[randomIntFromInterval(0,str.length - 1)].toUpperCase()
    }
    if(!containsNumbers(pass)){
      pass = pass + getRandomInt(1000);
    }
    return pass;
 }
 function randomIntFromInterval(min, max) { // min and max included 
  return Math.floor(Math.random() * (max - min + 1) + min)
 }
 function containsUppercase(str) {
  return /[A-Z]/.test(str);
}
function containsNumbers(str) {
  return /\d/.test(str);
}
function getRandomInt(max) {
  return Math.floor(Math.random() * max);
}
  
  //fin validation step 2
