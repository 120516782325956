import React, { useContext, useEffect, useState } from 'react'
import "./DossierDetails.scss";
import MainClass from '../../utils/classes/MainClass';
import { FaEdit } from "react-icons/fa";
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment/moment';
import { FaSave } from "react-icons/fa";
import Erreurs from '../../components/erreurs/Erreurs';
import toast from 'react-hot-toast';
import { UserContext } from '../../utils/context/contex';
import { useForm } from 'react-hook-form';
import Swal from "sweetalert2"
import RejectDossierModal from '../../components/RejectDossierModal/RejectDossierModal';
function DossierDetails() {
  const api = new MainClass();  
  const { id } = useParams();
  const [isLoading ,setIsLoading ] = useState(false);
  const [ dossierInfo ,setDossierInfo ] = useState('');
  const [ erreurs,setErreurs ] = useState([]);
  const navigate = useNavigate();
  const [ isOpen, setIsOpen ] = useState(false)
  const numberPattern = /^[a-zA-z]{1,3}(-){1}[0-9].{0,3}(\s|-){1}([a-zA-z]|[0-9]){1,3}|\s[0-9]{1,3}$/;
  const [isChassisUpdate,setIsChassisUpdate ] = useState(false);
  const [chassisValue,setChassisValue ] = useState('');
  const [initialchassisValue,setInitialChassisValue ] = useState('')
  const [ immatriculationNumber,setImmatriculationNumber ] = useState('');
  const { register, handleSubmit, reset,formState: { errors } } = useForm({
  
  });
  
  const { user } = useContext(UserContext);

  const handleValider = async (e) => {
    e.preventDefault();
    setErreurs([]);setIsLoading(true);
    const { status,errors } = await api.apiData("get",`/dossier/valider/${id}`);

    setIsLoading(false);
    // console.log(status)
    if( status === 200 ){
       toast.success("Dossier Validé avec succès.");
       navigate("/dossier/liste");
  
    }
    else {
       setErreurs(errors);
    }
    // console.log('ddlk')
  } 
  const getDossierInfo = async(id) => {
    const { status ,dossier } =  await api.apiData("get",`/dossier/getbyID/${id}`);
    if( status === 200 ){
        if( dossier.length > 0 ){
            setDossierInfo(dossier[0]);
            setChassisValue(dossier[0]?.vehicule?.chassis);
       
            setInitialChassisValue(dossier[0]?.vehicule?.chassis)
        }
    }
  } 
  const handleInput = (e) => {
    setImmatriculationNumber(e.target.value)
  }
  useEffect(() => {
    getDossierInfo(id)
  },[id]);
  const AjoutNumero = async () => {
     var formdata = new FormData();
     formdata.append("numeroImmatriculation",immatriculationNumber);
     setIsLoading(true);setErreurs([]);
     const { status,errors } = await api.apiData("post",`/dossier/valider/${id}`,formdata);
     setIsLoading(false);
     if( status === 200 ){
         navigate("/dossier/liste");
         toast.success("Validation effectuée avec succès.");  
     }
     else {
         setErreurs(errors)
     }
  }
  const saveUpdatedChassis = (e) => {
  
    Swal.fire({
      title: 'Voulez-vous vraiment modifier ce numéro de chassis ?',
      // text: "Vous ne pourrez plus revenir en arrière",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#2a9d8f',
      cancelButtonColor: '#e63946',
      confirmButtonText: 'Valider',
      cancelButtonText: "Annuler"
    }).then((result) => {
      setIsChassisUpdate(false);
      if (result.isConfirmed) {
           var formdata = new FormData();
           formdata.append("id",dossierInfo._id);
           formdata.append("newchassis",chassisValue);
           api.apiData("post",`dossier/editChassis/${dossierInfo._id}`,formdata).then((resp) => {
              if( resp.status === 200 ){
                toast.success("Modification effectuée avec succès")
              }
              else{
                toast.error(resp.errors[0]?.message);
              }
           })
      }
      else{
         setChassisValue(initialchassisValue)
      }  
      })
   
  }
  const handleChassiInput = (e) => {
      setChassisValue(e.target.value);
      
  }

  return (
    <div className='page dossier-details'>
         <div className='dossier-details__header'>
            <h3>Details du Dossier</h3>
         </div>
         <RejectDossierModal isOpen={isOpen} setIsOpen={setIsOpen} id={id} />
         {isLoading && <p className='loading'>Chargement En cours....</p>}
         
          
          {(dossierInfo && !isLoading)?
            <>
            <div className='dossier-details__body'>
            
             <div className='dossier-details__body__left'>
           
               <div className='dossier-details__body__left__header'>
                  <div>
                    <p>Numéro: </p>
                    <h2>{ dossierInfo.numero }</h2>
                  </div>
                  <div>
                     <p>Status: </p>
                    <h2>{ dossierInfo.status.charAt(0).toUpperCase() + dossierInfo.status.slice(1)}</h2>
                  </div>
                  
               </div>
            
               <p>Information Client</p>
               <hr />
               <div className='field'>
                   <p>Prenom</p>
                   <p>{ dossierInfo.client.prenom }</p>
               </div>
               <div className='field'>
                   <p>Nom</p>
                   <p>{ dossierInfo.client.nom }</p>
               </div>
               <div className='field'>
                   <p>Telephone</p>
                   <p>{ dossierInfo.client.telephone }</p>
               </div>
               <div className='field'>
                   <p>Email</p>
                   <p>{ dossierInfo.client.email }</p>
               </div>
               <div className='field'>
                   <p>Date d'initiation</p>
                   <p>{ moment(dossierInfo.created_at).format("Do MMMM YYYY") }</p>
               </div>
               {dossierInfo.status !== "initié" &&
                <div className='field'>
                   <p>Date de traitement</p>
                   <p>{ moment(dossierInfo.updated_at).format("Do MMMM YYYY") }</p>
               </div>}
           </div> 
         
           <div className='dossier-details__body__right'>
              <div className='dossier-details__body__right__header'>
                  <p>Information Deposant</p>
                  <hr />
                  <div className='field'>
                    <p>Prenom</p>
                    <p>{ dossierInfo.deposant.prenom }</p>
                  </div>
                  <div className='field'>
                    <p>Nom</p>
                    <p>{ dossierInfo.deposant.nom }</p>
                  </div>
                  <div className='field'>
                    <p>Telephone</p>
                    <p>{ dossierInfo.deposant.telephone }</p>
                  </div>
               </div>
           </div>  
            </div>
            <div className='dossier-details__chassis'>
              <div className='dossier-details__chassis__header'>
                 <p>Information u Vehicule</p>
                 <hr />
              </div>
               <div className='dossier-details__chassis__body'>
                  <div className='field chassis-update'>
                     <p>Chassis: </p>
                     {(!isChassisUpdate) && <h2>{ chassisValue?.toUpperCase() }</h2>}
                    
                      {(isChassisUpdate && user?.role === "edition") &&
                       <div className='input-edit-chasis' >
                           <input type='text'  value={chassisValue} name="newChassisValue" 
                              onChange={handleChassiInput}
                           /> 
                           <div className='input-save-chassis' onClick={saveUpdatedChassis}>
                             <FaSave/>
                           </div>  
                      </div>  
                    
                    }
                   {(!isChassisUpdate && user?.role === "edition") &&
                     <div className='edit-btn' onClick={() => setIsChassisUpdate(true)}>
                       <FaEdit size={24}/>
                     </div>}
                 </div>
                {dossierInfo?.vehicule.immatriculationNumber &&
                  <div className='field'>
                    <p>Numéro d'immatriculation: </p>
                    <h2>{ dossierInfo?.vehicule.immatriculationNumber }</h2>
                  </div>
                }
             
               </div> 
               {dossierInfo.status === "rejeté" &&
                <div className='dossier-details__chassis__body' style={{ marginTop:'10px '}}>
                 <div className='field'>
                    <p>Raison du Rejet: </p>
                     <h2>{ dossierInfo?.motif }</h2>
                 </div>
               </div>}
               {(user.role === "superviseur" && dossierInfo.status === "initié") &&
                  <form onSubmit={handleSubmit(AjoutNumero)} style={{ marginTop:'10px'}}>
                    <div className='input-group'>
                     <label>Numéro Immatriculation
                       <input type='text' 
                            style={{ maxWidth: '240px',marginTop:'15px'}}
                            placeholder="ex:A-000-01"
                            value={immatriculationNumber?.toUpperCase()}
                            className="numeroImmatriculation"
                            name="numeroImmatriculation"
                          {...register('numeroImmatriculation', {
                            onChange: (e) => {
                                handleInput(e)
                            },
                            required:true,
                            pattern:{
                                value: numberPattern,
                                message:"Respectez le format d'immatriculation (ex:A-0993-02)"
                            }
                            },
                          )}
                          />
                         {errors.numeroImmatriculation && errors.numeroImmatriculation?.type === "required" && (
                          <span className="error-msg">Le numero d'immatriculation est obligatoire.</span>
                          )}
                           {errors.numeroImmatriculation && (
                               <span className="error-msg">{errors.numeroImmatriculation.message}</span>
                            )}
                     </label>
                      
                    </div>
                    <Erreurs validation={erreurs}/>
                    <div className='btn-group'>
                      <button className='success' type='submit'>Valider</button>
                      <button className='delete' type='button' onClick={() => setIsOpen(!isOpen)}>Rejeter</button>
                    </div>
                  </form>
                 }
              </div>
      
            
           
            </>:
            <p className='not-found'>Information non trouvée</p>
           }
          
         
        
    </div>
  )
}

export default DossierDetails