import React, { useContext, useEffect, useState } from 'react'
import "./DossierDetailsGroup.scss";
import MainClass from '../../utils/classes/MainClass';
import { TiDeleteOutline } from "react-icons/ti";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import moment from 'moment/moment';
import Erreurs from '../../components/erreurs/Erreurs';
import toast from 'react-hot-toast';
import { UserContext } from '../../utils/context/contex';
import { useForm } from 'react-hook-form';
import { motion } from 'framer-motion';
import RejectDossierModal from '../../components/RejectDossierModal/RejectDossierModal';
const container = {
    hidden:{ opacity: 0 ,
        transition:{type: "spring",duration: .7,damping: 300}
    },
    show: {
        opacity: 1,
       
    }
}
const item = {
    hidden: { opacity: 0 },
    show: { opacity: 1 }
  }
function DossierDetailsGroup() {
  const api = new MainClass();  
  const { state } = useLocation();
  
  const [isLoading ,setIsLoading ] = useState(false);
  const [ dossierInfo ,setDossierInfo ] = useState('');
  const [ erreurs,setErreurs ] = useState([]);
  const navigate = useNavigate();
  const [ isOpen, setIsOpen ] = useState(false)
  const numberPattern = /^[a-zA-z]{1,3}(-){1}[0-9].{0,3}(\s|-){1}([a-zA-z]|[0-9]){1,3}|\s[0-9]{1,3}$/;
  const [ immatriculationNumber,setImmatriculationNumber ] = useState('');
  const[ dossiersInfos ,setDossiersInfos ] = useState(state?.selectedDossier);
  const [ selectedID, setSelectedID ] = useState(0);
  
  const { register, handleSubmit, reset,formState: { errors } } = useForm({
  
  });
  const [ formsErrors ,setFormsErrors ] = useState([]);
  
  const { user } = useContext(UserContext);

//   const getDossiersInfos = async(dossiers) => {
//     console.log(dossiers)
//     // const { status ,dossier } =  await api.apiData("get",`/dossier/getbyID/${id}`);
//     // console.log(dossier)
//     // if( status === 200 ){
//     //     if( dossier.length > 0 ){
//     //         setDossierInfo(dossier[0]);
//     //     }
//     // }
//   } 
  const handleInput = (index,value) => {
    const doc = [...dossiersInfos];
    clearError();
    doc[parseInt(index)]['numeroImmatriculation'] = value.toUpperCase();
    setDossiersInfos(doc);   
  }

  useEffect(() => {
   
   if( state?.selectedDossier.length === 0 ){
     toast.error("Veuillez côcher les dossiers à valider.");
     navigate("/dossier/liste");
    }
    else {
        const arr = [];
        dossiersInfos.map((dossier,index) => {
            
            const errors = {
                
                message: ''
            }
            arr.push(errors);
        });
        setFormsErrors(arr);
    }
  
  },[state?.selectedDossier[0]?.id]);
 function getSelecteID(index,e) {
    if ( dossiersInfos[index]?.id ){
        setSelectedID(dossiersInfos[index]?.id);
        setIsOpen(!isOpen);
    }
    else{
        toast.error("Identifiant trouvé.")
    }
   
 }
//   console.log(dossiersInfos[0]?.numeroImmatriculation);
  const handleValider = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    if(typeof  e.target.querySelector("input[name='index']") !== 'undefined' ){
        const _index = e.target?.querySelector("input[name='index']").value;
        const isErrors = false;
      
        clearError();
        if( dossiersInfos[_index]?.numeroImmatriculation?.length === 0 || typeof dossiersInfos[_index]?.numeroImmatriculation === 'undefined' ){
            const errors = formsErrors;
            errors[_index].message = "Veuillez fournir le numéro d'immatriculation";
            setFormsErrors(errors);
            const dossiers = [...dossiersInfos];
            dossiers["reload"] = dossiers["reload"]?!dossiers["isUpdate"] : true;
            setDossiersInfos(dossiers)
          
        }
        else if(!numberPattern.test(dossiersInfos[_index]?.numeroImmatriculation)) {
            const errors = formsErrors;
            errors[_index].message = "Respectez le format d'immatriculation (ex:A-0993-02)";
            setFormsErrors(errors);
            const dossiers = [...dossiersInfos];
            dossiers["reload"] = dossiers["reload"]?!dossiers["isUpdate"] : true;
            setDossiersInfos(dossiers)
        }
        else {

            var formdata = new FormData();
            formdata.append("numeroImmatriculation",dossiersInfos[_index]?.numeroImmatriculation);
            const id = dossiersInfos[_index]?.id
            setIsLoading(true);setErreurs([]);
            const { status,errors } = await api.apiData("post",`/dossier/valider/${id}`,formdata);
            setIsLoading(false);
            if( status === 200 ){
                const dossiers = dossiersInfos.filter((p) => p.id !== dossiersInfos[_index]?.id);
                if( dossiers.length > 0 ){
                    setDossiersInfos(dossiers);
                    state.selectedDossier = dossiers;
                    navigate("/dossier/details/group",{ state:{selectedDossier : dossiers }});
                }
                else {
                    navigate("/dossier/details/group",{ state:{selectedDossier : [] }});
                    toast.success("Tous les dossiers sont traités avec succès.")
                    navigate("/dossier/liste");
                }
                
            }
            else {
                setErreurs(errors)
            }
            
        }
    
       
    }
    setIsLoading(false)
    // dossiersInfos.forEach((dossier,ind) => {
    //     if( ind === index ){
    //         console.log(index)
    //     }
    //     //  if( parseInt(index) !== parseInt(ind) ){
    //     //     errors["numeroImmatriculation" + ind] = ""
    //     //  }
    // })
    //  var formdata = new FormData();
    //  formdata.append("numeroImmatriculation",immatriculationNumber);
    //  setIsLoading(true);setErreurs([]);
    //  const { status,errors } = await api.apiData("post",`/dossier/valider/${id}`,formdata);
    //  setIsLoading(false);
    //  if( status === 200 ){
    //      navigate("/dossier/liste");
    //      toast.success("Validation effectuée avec succès.");  
    //  }
    //  else {
    //      setErreurs(errors)
    //  }
  }
  function clearError(){
    const arr = [];
    formsErrors.forEach(() => {
        const errors = {
            numeroImmatriculation : '',
            message: ''
        }
        arr.push(errors);
    });
    setFormsErrors(arr)
  }
  function deleteElement(e,index){
    const dossiers = dossiersInfos.filter((p) => p.id !== dossiersInfos[index]?.id);
    // console.log(e.target.parentNode.parentNode.parentNode);
    var elemt = e.target.parentNode.parentNode.parentNode;
    elemt.style.opacity = 0;
   //console.log()
    if( dossiers.length > 0 ){
        setDossiersInfos(dossiers);
        state.selectedDossier = dossiers;
       navigate("/dossier/details/group",{ state:{selectedDossier : dossiers }});
    }
    else navigate("/dossier/liste")
  }
//console.log(formsErrors[3]?.message);
const renderInputFields = () => {
   
     if( !isLoading){
      return dossiersInfos.map((dossierInfo,index) => 
       (
         <motion.div key={dossierInfo?.id + (index + Math.random())} className='dossier-info'
           variants={container}
           initial="hidden"
           animate="show"
         >
            <div className='numerotation-delete'>
                <div className='numerotation'>
                   <p>{index + 1}</p>
               </div>
               <div className='nume-delete' onClick={(e) => deleteElement(e,index)}>
                  <TiDeleteOutline size={50}/>
               </div>
            </div>
             
           <div className='dossier-details__body' key={dossierInfo?.id + (index+ 0 + 8)}>
            <div className='dossier-details__body__left' key={dossierInfo?.id + Math.random()}>
               <div className='dossier-details__body__left__header'>
                   <div>
                       <p>Numéro: </p>
                       <h2>{ dossierInfo.numero }</h2>
                   </div>
                   <div>
                       <p>Status: </p>
                       <h2>{ dossierInfo.status.charAt(0).toUpperCase() + dossierInfo.status.slice(1)}</h2>
                   </div>
                   
               </div>
               
               <p>Information Client</p>
               <hr />
               <div className='field'>
                   <p>Prenom</p>
                   <p>{dossierInfo.prenomClient}</p>
               </div>
               <div className='field'>
                   <p>Nom</p>
                   <p>{ dossierInfo?.nomClient }</p>
               </div>
               <div className='field'>
                   <p>Telephone</p>
                   <p>{dossierInfo.telephoneClient}</p>
               </div>
               <div className='field'>
                   <p>Email</p>
                   <p>{ dossierInfo.emailClient }</p>
               </div>
               <div className='field'>
                   <p>Date d'initiation</p>
                   <p>{dossierInfo.date}</p>
               </div>
               {dossierInfo.status !== "Initié" &&
                   <div className='field'>
                   <p>Date de validation</p>
                   <p>{ dossierInfo.date_updated }</p>
               </div>}
            </div> 
       
           <div className='dossier-details__body__right'  key={dossierInfo?.id + (index + 1)}>
               <div className='dossier-details__body__right__header'>
                   <p>Information Deposant</p>
                   <hr />
                   <div className='field'>
                       <p>Prenom</p>
                       <p>{dossierInfo.prenomDeposant}</p>
                   </div>
                   <div className='field'>
                       <p>Nom</p>
                       <p>{ dossierInfo.nomDeposant}</p>
                   </div>
                   <div className='field'>
                       <p>Telephone</p>
                       <p>{ dossierInfo.telephoneDeposant }</p>
                   </div>
               </div>
           </div>  
           </div>
           <div className='dossier-details__chassis' key={dossierInfo?.id + (index + 2)}>
            <div className='dossier-details__chassis__header'>
               <p>Information du Véhicule</p>
               <hr />
            </div>
            <div className='dossier-details__chassis__body'>
               <div className='field'>
                   <p>Chassis: </p>
                   <h2>{ dossierInfo.chassis }</h2>
               </div>
              
               {/* <div className='field'>
                   <p>Numéro d'immatriculation: </p>
                   <h2>{  }</h2>
               </div> */}
               
           
           </div> 
           {/* {(user.role === "superviseur" && dossierInfo.status === "Initié") && */}
           {(user.role === "superviseur") &&
               <form onSubmit={handleValider}>
                     
                   <div className='input-group'>
                   <label>Numéro Immatriculation
                     <input type='text' 
                           style={{ maxWidth: '240px',marginTop:'15px',textTransform:"uppercase"}}
                           placeholder="ex:A-000-01"
                           autoFocus
                           value={dossiersInfos[index].numeroImmatriculation}
                           className="numeroImmatriculation"
                           name= "numeroImmatriculation"
                           onChange= {(e) => handleInput(index,e.target.value)}
                       />
                  
                           
                      {formsErrors[index]?.message && ( 
                         <span className="error-msg">{ formsErrors[index]?.message }</span>)
                      }  
                   </label>
                   
                   </div>
                   <Erreurs validation={erreurs}/>
                   <div className='btn-group'>
                    <input name='index' type='hidden' value={index}/>
                   <button className='success' type='submit'>Valider</button>
                   <button className='delete' type='button' onClick={(e) => getSelecteID(index,e)}>Rejeter</button>
                   </div>
               </form>
               }
           </div>
         </motion.div> 
      )) ;
     }
     return <div>Patientez....</div>
}
  return (
    <div className='page dossier-details'>
         <div className='dossier-details__header'>
            <h3>Details du Dossier</h3>
         </div>
         <RejectDossierModal isOpen={isOpen} setIsOpen={setIsOpen} id={selectedID} />
         {isLoading && <p className='loading'>Chargement En cours....</p>}
      
          
          {dossiersInfos?.length > 0 ?
            <div key={1} 
           
            >
              {renderInputFields()}
           
            </div >:
            <p className='not-found'>Aucune Information non trouvée</p>
           }
          
         
        
    </div>
  )
}

export default DossierDetailsGroup;