import { useContext, useEffect,useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom'
import { isEmpty, isObjectEmpty } from "../../utils/helper/functions";
import { UserContext } from '../../utils/context/contex';
import MainClass from '../../utils/classes/MainClass';
import toast from 'react-hot-toast';
import Spinner from '../Spinner/Spinner';
const MobileNav = ({ isOpen,isLogging, setMenuOpen }) => {
  const { user } = useContext(UserContext);
  const api = new MainClass();
  const [isLoading,setIsLoading] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    if(isObjectEmpty(user)){
      setIsLoading(true)
    }else  setIsLoading(false)
    return () => {
      if( !localStorage.getItem('token'))
      {
        toast.error("Vous n'êtes pas connecté!");
        navigate('/');
        return;
      }
    }
  },[user]);
  return (
    
        <>
        {isLoading && <Spinner />}
          {isOpen ?(
          <div className={isOpen ? 'mobile-nav open' : 'mobile-nav'}>
            <ul>
                    
                 <NavLink className={(navData) => navData.isActive ? "link-active" : "" } to="/dashboard">
                  <li onClick={() => setMenuOpen(false)}>Tableau de bord</li>
                 </NavLink>
            
                 {(user?.role?.toLowerCase() === "admin" || user?.role?.toLowerCase() === "service impression") &&
                   <NavLink key={2} className={(navData) => navData.isActive ? "link-active" : "" } to="/nouvelle-impression">
                     <li>Nouvelle Impression</li>
                 </NavLink>}

                 {(user?.role?.toLowerCase() === "admin" || user?.role?.toLowerCase() === "management") &&
                   <NavLink key={3} className={(navData) => navData.isActive ? "link-active" : "" } to="/liste-imprimeur">
                     <li>Liste Imprimeur</li>
                 </NavLink>}
                 <NavLink key={4} className={(navData) => navData.isActive ? "link-active" : "" } to="/list-impression">
                     <li>Les Listes</li>
                 </NavLink>
                 {user?.role?.toLowerCase() === "admin" &&
                   <NavLink key={5} className={(navData) => navData.isActive ? "link-active" : "" } to="/utilisateur">
                     <li>Utilisateur</li>
                 </NavLink>}

                 {/* <NavLink key={5} className={(navData) => navData.isActive ? "link-active" : "" } to="#">
                       <li>Immatriculations</li>
                 </NavLink> */}
             
                   {/* <div className="link-reforme" >
                       <li>Reformes</li>
                       <div className="dropdown-menu dropdown-reforme">
                          
                             <NavLink key={6} className={(navData) => navData.isActive ? "link-active link-reforme" : "link-reforme" } to="#">
                                 <li>Nouvelle reforme</li>
                             </NavLink>
                     
                           <NavLink key={8} className={(navData) => navData.isActive ? "link-active link-reforme" : "link-reforme" } to="#">  
                               <li>Liste des reformes</li>
                           </NavLink>
                           
                       </div>     
                   </div>    */}
                
               </ul>
          </div>):null
          }
        </>
        
      )
    
  
};

export default MobileNav;
