import React, { useEffect, useState } from 'react'
import "./NouveauDossier.scss";
import { useForm } from 'react-hook-form';
import { useContext } from 'react';
import { ElementContext } from '../../utils/context/contex';
import { colors } from '../../utils/helper/colors';
import { FirstUpperCase, objecttoFormData } from '../../utils/helper/functions';
import { useNavigate } from 'react-router-dom';
import MainClass from '../../utils/classes/MainClass';
import Erreurs from '../../components/erreurs/Erreurs';

function NouveauDossier() {
    const [ dossier,setDossier ] = useState({
        prenomClient:'',
        nomClient: '',
        telephoneClient: "",
        emailClient: "",
        adresseClient: "",
        prenomDeposant: "",
        nomDeposant: "",
        telephoneDeposant: "",
        adresseDeposant: "",
        chassis: "",
       
    });
   
    const [ valueChbox , setValueChbox ] = useState(false);
    const [ selectedModele,setSelectedmodele ] = useState([]);
    const [ isLoading ,setIsLoading ] = useState(false);
    const [erreurs,setErreurs ] = useState([]);

    const api = new MainClass();
    const { register, handleSubmit, reset,setValue,formState: { errors } } = useForm({
        defaultValues:{
          dossier
        }
    }); 
    const navigate = useNavigate();

    const handleInput = (e) => {
        const field = e.target.name;
        if( valueChbox ){
         
            setDossier({...dossier,[e.target.name]: e.target.value,[field.replace("Client","Deposant")]:e.target.value });
        }else{
            setDossier({...dossier,[e.target.name]: e.target.value});
        }
         
    } 
    
    const submitForm = async () => {
      // console.log('ddd');
      var formdata = objecttoFormData(dossier);
      setErreurs([]);setIsLoading(true)
      const { status , dossierDocument,errors } = await api.apiData("post","/dossier/nouveau",formdata);
      setIsLoading(false)
      if( status === 200 ){
          navigate(`/dossier/details/${dossierDocument._id}`)
      }
      else{
          setErreurs(errors)
      }
     
    }
  const handleCheckox = (e) => {
    const chk = !valueChbox;
  
    setValueChbox(!valueChbox);
    if( chk ){
        setDossier({...dossier,
            prenomDeposant:dossier.prenomClient,
            nomDeposant: dossier.nomClient,
            telephoneDeposant: dossier.telephoneClient,
            adresseDeposant: dossier.adresseClient
        });
       
    }
    else{
        setDossier({...dossier,
            prenomDeposant: '',
            nomDeposant: "",
            telephoneDeposant: "",
            adresseDeposant: ""
        })
    }
  }
//  console.log(errors.prenomDeposant)
  return (
    <div className='page nouveau-dossier'>
       <div className='nouveau-dossier__header'>
         <h2>Nouveau Dossier</h2>
         <p>Information du client</p>
       </div>
      <hr />
      <form onSubmit={handleSubmit(submitForm)}>
         <div className='input-group'>
            <label>Prénom <span className='obligatoire'>*</span>
              <input type='text' name='prenomClient' placeholder="Prénom du Client" autoFocus autoComplete='off'
               value={FirstUpperCase(dossier.prenomClient)}
                {...register('prenomClient', {
                    onChange: (e) => {
                         handleInput(e)
                    },
                  required:true,minLength:3},
                )}
              />
              {errors.prenomClient && errors.prenomClient?.type === "required" && (
                 <span className="error-msg">Le prénom du client est obligatoire</span>
              )}
              {errors.prenomClient && errors.prenomClient?.type === "minLength" && (
                 <span className="error-msg">Le caractère minimum est trois (3).</span>
              )}
          
            </label>
            <label>Nom <span className='obligatoire'>*</span>
              <input type='text' placeholder="Nom"  name='nomClient' autoComplete='off'
               value={dossier.nomClient.toUpperCase()}
               {...register('nomClient', {
                onChange: (e) => {
                     handleInput(e)
                },
                required:true,maxLength:17,minLength:3},
               )}
              />
              {errors.nomClient && errors.nomClient?.type === "required" && (
                 <span className="error-msg">Le nom du client est obligatoire</span>
              )}
              {errors.nomClient && errors.nomClient?.type === "minLength" && (
                 <span className="error-msg">Le caractère minimum est trois (3).</span>
              )}
              {errors.nomClient && errors.nomClient?.type === "maxLength" && (
                 <span className="error-msg">Le caractère maximum est dix sept (17).</span>
              )}
            </label>
            <label>Téléphone <span className='obligatoire'>*</span>
              <input type='text' placeholder="Téléphone"  name='telephoneClient'
                value={dossier.telephoneClient}
                {...register('telephoneClient', {
                    onChange: (e) => {
                        handleInput(e)
                    },
                required:true,
                pattern: /6[0-9]{8}$/g,
                },
                )}
              />
             
              {errors.telephoneClient && errors.telephoneClient?.type === "required" && (
                <span className="error-msg">Ce champ est obligatoire.</span>
              )}
               {errors.telephoneClient && errors.telephoneClient?.type === "minLength" && (
                   <span className="error-msg">Le numéro de téléphone ne peut pas être inférieur à 9 caractères.</span>
               )}
               {errors.telephoneClient && errors.telephoneClient?.type === "pattern" && (
                   <span className="error-msg">Le Format du numéro de téléphone est 620 000 000.</span>
               )}
            </label>
           
         </div>
         <div className='input-group'>
           <label>Email
                <input type='text' placeholder="Email"  name='emailClient'
                 {...register('emailClient', {
                    onChange: (e) => {
                         handleInput(e)
                    },
                  required:false,
                  pattern: /\S+@\S+\.\S+/g,
                },
                )}
                />
             
                 {errors.emailClient && errors.emailClient?.type === "pattern" && (
                    <span className="error-msg">Le Format du numéro de email est example@example.com.</span>
                )}
            </label>
            <label>Adresse
                <input type='text' placeholder="Adresse"  name='adresseClient'
                 {...register('adresseClient', {
                    onChange: (e) => {
                         handleInput(e)
                    },
                    required:false,minLength:3},
                 )}
                />
             
               {errors.adresseClient && errors.adresseClient?.type === "minLength" && (
                 <span className="error-msg">L'adresse ne peut pas être inférieur à 9 caractères.</span>
               )}
            
            </label>
         </div>
         <div className='nouveau-dossier__information-deposant'>
            <p>Information du Déposant</p>
            <hr />
           <div className='check-btn-grp'>
                <label>Mêmes Informations que le client</label>
                <input type="checkbox"  checked={valueChbox} onChange={(e) => handleCheckox(e)}/>
               
           </div>
         </div>
         {!valueChbox &&  
         <div className='input-group'>
           <label>Prénom <span className='obligatoire'>*</span>
              <input type='text' placeholder="Prénom du Déposant"  name='prenomDeposant'
                value={FirstUpperCase(dossier.prenomDeposant)}
                {...register('prenomDeposant', {
                    onChange: (e) => {
                        handleInput(e)
                    },
                    required:true,maxLength:17,minLength:3},
                )}
              />
              {errors.prenomDeposant && errors.prenomDeposant?.type === "required" && (
                 <span className="error-msg">Le prénom du deposant est obligatoire</span>
              )}
              {errors.prenomDeposant && errors.prenomDeposant?.type === "minLength" && (
                 <span className="error-msg">Le caractère minimum est trois (3).</span>
              )}
              {errors.prenomDeposant && errors.prenomDeposant?.type === "maxLength" && (
                 <span className="error-msg">Le caractère maximum est dix sept (17).</span>
              )}
            </label>
            <label>Nom <span className='obligatoire'>*</span>
              <input type='text' placeholder="Nom du Client"  name='nomDeposant'
                value={dossier.nomDeposant.toUpperCase()}
                {...register('nomDeposant', {
                    onChange: (e) => {
                        handleInput(e)
                    },
                    required:true,minLength:3},
                )}
              />
              {errors.prenomDeposant && errors.prenomDeposant?.type === "required" && (
                 <span className="error-msg">Le nom du deposant est obligatoire</span>
              )}
              {errors.prenomDeposant && errors.prenomDeposant?.type === "minLength" && (
                 <span className="error-msg">Le caractère minimum est trois (3).</span>
              )}
             
            </label>
            <label>Téléphone <span className='obligatoire'>*</span>
              <input type='text' placeholder="Téléphone"  name='telephoneDeposant'
                value={dossier.telephoneDeposant}
                {...register('telephoneDeposant', {
                    onChange: (e) => {
                        handleInput(e)
                    },
                required:true,
                pattern: /6[0-9]{8}$/g,
                },
                )}
              />
               {errors.telephoneDeposant && errors.telephoneDeposant?.type === "required" && (
                <span className="error-msg">Ce champ est obligatoire.</span>
              )}
               {errors.telephoneDeposant && errors.telephoneDeposant?.type === "minLength" && (
                   <span className="error-msg">Le numéro de téléphone ne peut pas être inférieur à 9 caractères.</span>
               )}
               {errors.telephoneDeposant && errors.telephoneDeposant?.type === "pattern" && (
                   <span className="error-msg">Le Format du numéro de téléphone est 620 000 000.</span>
               )}
            </label>
            <label>Adresse
                <input type='text' placeholder="Adresse"  name='adresseDeposant'
                 value={dossier.adresseDeposant}
                 {...register('adresseDeposant', {
                    onChange: (e) => {
                         handleInput(e)
                    },
                    required:false,minLength:3},
                 )}
                />
                {errors.adresseDeposant && errors.adresseDeposant?.type === "minLength" && (
                   <span className="error-msg">L'adresse ne peut pas être inférieur à 9 caractères.</span>
               )}
            </label>
         </div>}
         <p className={`${valueChbox?"info-vehicule":""}`}>Information du Véhicule</p>
         <hr />
         <div className='input-group'>
            <label>Chassis <span className='obligatoire'>*</span>
              <input type='text' placeholder="Numéro de Chassis"  name='chassis'
              style={{ maxWidth:'270px'}}
               value={dossier.chassis.toUpperCase()}
                {...register('chassis', {
                    onChange: (e) => {
                         handleInput(e)
                    },
                required:true,maxLength:17,minLength:3},
                )}
              />
             {errors.chassis && errors.chassis?.type === "required" && (
                  <span className="error-msg">Le Numéro de Chassis est obligatoire.</span>
             )}
             {errors.chassis && errors.chassis?.type === "minLength" && (
                  <span className="error-msg">Le caractère minimum est trois (3).</span>
             )}
             {errors.chassis && errors.chassis?.type === "maxLength" && (
                  <span className="error-msg">Le caractère maximum est dix sept (17).</span>
             )}
            </label>
         </div>
            {/* <label>Marque <span className='obligatoire'>*</span>
                <select
                 {...register('marque_id', {
                    onChange: (e) => {
                        handleInput(e)
                    },
                    validate: (value) => value != ''},
                  )}
                 >
                  <option value ="">---</option>
                  {(elements?.marques.length > 0 )&& elements.marques.map((marque) => (
                    <option value={marque.id} key={marque._id}>{ marque.title }</option>
                  ))}
                </select>
                {errors.marque_id && (<span className="error-msg">Veuillez choisir la marque.</span>)} 
            </label>
            <label>Modèle <span className='obligatoire'>*</span>
              <select name='modele_id'
               {...register('modele_id', {
                onChange: (e) => {
                    handleInput(e)
                },
                validate: (value) => value != ''},
               )}
              >
              <option value="">---</option>
              {selectedModele.map((modele) => (
                <option value={modele.id} key={modele._id}>{ modele.title }</option>
              ))}
            </select>
               {errors.modele_id && (<span className="error-msg">Veuillez choisir le modèle.</span>)} 
            </label>
         </div>
         <div className='input-group'>
            <label>Couleur <span className='obligatoire'>*</span>
        
                 <select value={dossier.couleur} name='couleur'
                 {...register('couleur', {
                    onChange: (e) => {
                        handleInput(e)
                    },
                    validate: (value) => value != ''},
                 )}
                 >
                 <option value="">---</option>
                 {colors.length > 0 && colors.map((color) => (
                    <option value={color.name} key={color.id}>{ color.name }</option>
                  ))}  
                </select>
               {errors.couleur && (<span className="error-msg">Veuillez choisir la Couleur.</span>)}
    
             
             {dossier.couleur === "Autre" &&       
              <>
              <input type='text' name="autrecouleur" value={FirstUpperCase(dossier.autrecouleur)} autoFocus
                {...register('autrecouleur', {
                onChange: (e) => {
                    handleInput(e)
                },
                required:true,minLength:3},
                )}
              /> 
              {errors.autrecouleur && (<span className="error-msg">Veuillez Saisir la Couleur.</span>)}
             </>   
             } 
            </label>
            <label>Catégorie <span className='obligatoire'>*</span>
               <select name='categorie'
               value={dossier.categorie}
                 {...register('categorie', {
                    onChange: (e) => {
                        handleInput(e)
                    },
                    validate: (value) => value != ''},
                )}
               >
                  <option value="0">---</option>
                  {elements?.categories.length > 0 && elements?.categories.map((categorie) => ( 
                    <option value={categorie.categorie_id} key={categorie._id}>{categorie.nomCategorie}</option>
                  ))}
               </select>
               {errors.categorie && (<span className="error-msg">Veuillez choisir la categorie.</span>)} 
            </label>
         </div> */}
         <Erreurs validation={erreurs}/>
         <div>
            <button type='submit' disabled={isLoading}>{!isLoading ? "Valider" : "Patientez..."}</button>
         </div>
      </form>
    </div>
  )
}

export default NouveauDossier