import React,{ useState ,useContext,useEffect} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { BiUserCircle, BiLogOutCircle, BiMenu } from "react-icons/bi";
import { NavLink, Link } from "react-router-dom";
import MobileNav from "../MobileNav/MobileNav";
import logo from "../../assets/logo-dsd.png"
import Spinner from "../Spinner/Spinner";
import { UserContext } from "../../utils/context/contex";
import MainClass from "../../utils/classes/MainClass";
import toast from "react-hot-toast";
import { isObjectEmpty } from "../../utils/helper/functions";
//const user = useContext(useContext);

const Navbar = (isLogging) => {
  const location = useLocation();
  const pathName = location.pathname;
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const api = new MainClass();
  const [isLoading,setIsLoading] = useState(false);

  const disConnect = () => {
     api.removeToken();
     navigate("/");
  } 

  useEffect(() => {
    if(isObjectEmpty(user)){
      setIsLoading(true)
    }else  setIsLoading(false)
    return () => {
      if( !localStorage.getItem('token'))
      {
        toast.error("Vous n'êtes pas connecté!");
        navigate('/');
        return;
      }
    }
  },[user]);

   return (
    <>
     {isLogging  && pathName !== "/" ?
        <div className="Navbar">
          {(isLoading) && <Spinner />}
          <MobileNav 
           setMenuOpen={setIsMenuOpen} 
            isLogging ={isLogging}
            isOpen={isMenuOpen} 
          />
        
           {!isObjectEmpty(user) 
             && <main>
              <div className="left">
                <div className="hamburger-menu" onClick={() => setIsMenuOpen(!isMenuOpen)}>
                  <BiMenu />
                </div>
                <div className="logo">
                  <Link to="/dashboard" className="logo-wrapper">
                    <div className="logo-img">
                      <img src={logo} alt="" />
                    </div>
                    <div className="logo-text">
                      <p>Suivi-<span>Dossiers</span></p>
                      {/* <p>Les garages du gouvernement</p> */}
                    </div>
                  </Link>
                </div>
              </div>
              <div className="center">
                  <ul>
                 
                    <NavLink key={1} className={(navData) => navData.isActive ? "link-active" : "" } to="/dashboard">
                        <li>Tableau de bord</li>
                    </NavLink> 
                    {/* suivi des impressions */}
                   { (user?.role?.toLowerCase() === "admin" || user?.role?.toLowerCase() === "management" || user?.role?.toLowerCase() === "service impression") && 
                     <div className="link-reforme">
                        <li>Suivi des Impressions</li>
                        <div className="dropdown-menu dropdown-reforme">
                          {(user?.role?.toLowerCase() === "admin" || user?.role?.toLowerCase() === "service impression") &&
                           <li><NavLink key={2} className={(navData) => navData.isActive ? "link-active" : "" } to="/nouvelle-impression">
                              Nouvelle Impression
                           </NavLink>
                           </li>
                           }
                           {(user?.role?.toLowerCase() === "admin" || user?.role?.toLowerCase() === "management") &&
                           <NavLink key={3} className={(navData) => navData.isActive ? "link-active" : "" } to="/liste-imprimeur">
                                <li>Liste Imprimeur</li>
                           </NavLink>}
                          <NavLink key={4} className={(navData) => navData.isActive ? "link-active" : "" } to="/list-impression">
                              <li>Les Listes</li>
                          </NavLink>
                        </div>  
                     </div>  
                   }
                  
                    {(user?.role?.toLowerCase() === "service client"  || user?.role?.toLowerCase() === "superviseur" 
                    || user?.role?.toLowerCase() === "admin" || user?.role?.toLowerCase() === "edition") && 
                       <div className="link-reforme">
                          <li>Suivi des dossiers</li>
                          <div className="dropdown-menu dropdown-reforme">
                            {user?.role?.toLowerCase() !== "superviseur" &&
                             <li>
                               <NavLink key={2} className={(navData) => navData.isActive ? "link-active" : "" } to="/dossier/nouveau">
                                 Nouveau
                               </NavLink>
                              </li>
                              }
                              <li>
                                <NavLink key={2} className={(navData) => navData.isActive ? "link-active" : "" } to="/dossier/liste">
                                   Liste
                               </NavLink>
                              </li>
                          </div>
                       </div>
                   }
                   
                    {user?.role?.toLowerCase() === "admin" &&
                      <NavLink key={5} className={(navData) => navData.isActive ? "link-active" : "" } to="/utilisateur">
                        <li>Utilisateur</li>
                    </NavLink>}

                    {/* <NavLink key={5} className={(navData) => navData.isActive ? "link-active" : "" } to="#">
                          <li>Immatriculations</li>
                    </NavLink> */}
                
                      {/* <div className="link-reforme" >
                          <li>Reformes</li>
                          <div className="dropdown-menu dropdown-reforme">
                             
                                <NavLink key={6} className={(navData) => navData.isActive ? "link-active link-reforme" : "link-reforme" } to="#">
                                    <li>Nouvelle reforme</li>
                                </NavLink>
                        
                              <NavLink key={8} className={(navData) => navData.isActive ? "link-active link-reforme" : "link-reforme" } to="#">  
                                  <li>Liste des reformes</li>
                              </NavLink>
                              
                          </div>     
                      </div>    */}
                   
                  </ul>
                </div>
              <div className="right">
                <div className="avatar">
                  <div className="user-info">
                    {!isObjectEmpty(user) &&
                    <>
                       <span className="name">{user?.prenom + " " + user?.nom}</span>
                       <span className="role">{user.role} {user?.localite?.length > 0 && <span className="localite">{user.localite[0].nom}</span>}</span>
                    </>
                    }
                   
                  </div>
                  <div className="user-icon">
                    <BiUserCircle />
                  </div>
                </div>
                <div className="separator"></div>
                <div onClick={() => disConnect()} className="disconnect">
                  <div className="logout">
                    <BiLogOutCircle />
                  </div>
                </div>
              </div>
            </main>}
            
        </div>
     :null}
    
    </>
  );
};

export default Navbar;
