import axios from "axios";

export default class MainClass{
    constructor(){
      this.data = null;
      this.url = process.env.REACT_APP_URL;
      this.token = localStorage.getItem('token');
      this.headers = {
        "Content-Type": "multipart/form-data"
      }
      if( this.token )
        this.headers.Authorization = `Bearer ${this.token}`;
      
      this.client = axios.create({
        baseURL:this.url,
        headers: this.headers
      });  
    } 
    setToken(token) {
        localStorage.setItem('token',token)
    }
    removeToken(){
      localStorage.removeItem('token');
    }
    handleError(e){
      this.response = {
        status : 200,
        errors : []
      }

      if( e?.code === 'ERR_BAD_REQUEST' ){
        this.response.status = 401;
        this.response.errors.push({field:'error','message':'Mauvais requete.'});
        return this.response;
      
      }
    
         this.response.status = 401;
         this.response.errors.push({field:'error','message':'Ne peut pas lire la requete actuelle.'})
      
      return this.response;
    }
      async apiData (method = 'get',url,data = ''){
        try{
          if ( method === 'post' ){
              await this.client.post(url,data).then((response) => {
                this.data = response.data;
              });    
          }
          else {
              await this.client.get(url,data).then((response) => {
                  this.data = response.data ;
                });  
          }
          
        }
        catch(e){
  
           this.data = {
            status:500,
            error:{
              message:'Erreur de connexion'
            }
           }
        }
  
     return this.data;
    }
}