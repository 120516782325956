import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import Spinner from '../../components/Spinner/Spinner'
import Box from "@mui/material/Box";
import { DataGridPremium, GridToolbar, frFR } from "@mui/x-data-grid-premium";
import MainClass from '../../utils/classes/MainClass';
import { useNavigate } from 'react-router-dom';

function ListImprimeur() {
  const [ isLoading ,setIsLoading ] = useState(false);  
  const [ erreurs , setErreurs ] = useState([]);
  const [ data, setData ] = useState([]);
  const navigate = useNavigate();
  const api = new MainClass();
  const details = (id) => {
   navigate(`/imprimeur/details/${id}`)
  }
  const options = (params) => {
    const _id = params.row._id;
    
    return (
        <div className="options">
             <button onClick={() => details(_id)} >Voir Détails</button>                               
        </div>
    );
  }
const columnDefs = [
    { headerName: "_id", field: "_id", minWidth: 0,hide:true},
    { headerName: "N°Ord.", field: "id", maxWidth: 70 },
    { headerName: "Prénom et Nom", field: "prenom_nom",  minWidth: 250 , flex: 1, },
    { headerName: "Téléphone", field: "telephone", minWidth: 120},
    { headerName: "Email", field: "email",minWidth: 210 },
    { headerName: "Centre", field: "centre",minWidth:180 },
    { headerName: "Nombre Imp.", field: "nbreImp",maxWidth:100 },
    { headerName: "Options", field: "options", minWidth:150,renderCell: options ,  sortable: false,}, 
  ];
  const getImprimeurInfo = async () => {
    setIsLoading(true);setErreurs([]);
    const {status , PrintCount, errors} = await api.apiData("get","/impression/listparImprimeur"); 
    console.log(errors)
    setIsLoading(false);
    if(status === 200 ){
        
        setData(PrintCount.map((printer,index) => {
            return {
                _id: printer._id, 
               id: index + 1,
               prenom_nom : printer.user.prenom + " " + printer.user.nom  ,
               email : printer.user.email ,
               telephone : printer.user.telephone,
               nbreImp : printer.TotalImp,
               centre: printer.prefecture.nom
            }
        }))
    }
  else setErreurs(errors);  
  }
  useEffect(() => {
    getImprimeurInfo();
  },[])
  return (
    <div className='page'>
        <div className='header'>
            {isLoading && <Spinner />}
            <Helmet>
                <title>Liste des Imprimeurs</title>
            </Helmet>
            <h2>Liste des Imprimeurs</h2>
        </div>
        <div className='body'>
            {data.length > 0 ?
                <div className="array">
                        <Box sx={{ height: 579, width:"90%" }}>
                        <DataGridPremium
                            sx={{ borderRadius: 0 }}
                            density="compact"
                            components={{ Toolbar: GridToolbar}}
                            rows={data}
                            columns={columnDefs}
                            autoPageSize
                            pagination
                            disableSelectionOnClick
                            localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                            />
                        </Box>
                </div>:<p>Aucune Impression effectuée</p>
            }
        </div>
   </div>  
  )
 }
export default ListImprimeur;