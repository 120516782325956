import React, { useContext, useState } from 'react'
import "./NewUser.css";
import { LocaliteContext } from '../../../utils/context/contex';
import { useForm } from 'react-hook-form';
import { FirstUpperCase, makePassword, objecttoFormData } from '../../../utils/helper/functions';
import Spinner from '../../../components/Spinner/Spinner';
import MainClass from '../../../utils/classes/MainClass';
import { useNavigate } from 'react-router-dom';
import Erreurs from '../../../components/erreurs/Erreurs';
import toast from 'react-hot-toast';
import { ElementContext } from '../../../utils/context/contex';
function NewUser() {
  const { localites  } = useContext(LocaliteContext);
  const { elements } = useContext(ElementContext);
  const { centres } = elements?elements:'';
  
  const [ isLoading,setIsLoading ] = useState(false);
  const [ erreurs,setErreurs ] = useState([]);
  const navigate = useNavigate();
  const password = makePassword(8);
 
  const [userInfo,setUserInfo ] = useState({
    prenom:'',
    nom: '',
    telephone: '',
    email: '',
    role: 'management',
    prefecture_id : 7,
    centre_id: "",
    password: password,
    confirmPassword: password
  });
  const { register, handleSubmit, reset,formState: { errors } } = useForm({
    defaultValues:{
        userInfo
    }
});
const api = new MainClass();

const handleInput = (e) => {
    setUserInfo({...userInfo,[e.target.name] : e.target.value });
}

const submitForm = async () => {
    setErreurs([]);setIsLoading(true);
     let data = userInfo;
     delete data.prefecture_id
     data = objecttoFormData(data);
    const response = await api.apiData('post',"/user/registration",data);
    console.log(response)
    setIsLoading(false);
    if( response.status === 200 ){
        if(response.user.isMailSent){
            toast.success("Inscription effectuée avec succès.")
       }
       else {
          toast.success("Inscription effectuée avec succès mais le mail n'a pas été livré.")
       }
       navigate('/utilisateur');
    }
    else setErreurs(response.errors);
}
  return (
    <div className='page'>
        {isLoading && <Spinner />}
         <div className='head'>
            <div className='title'>Nouveau Utilisateur</div>     
        </div>
        <div className='body'>
            <form onSubmit={handleSubmit(submitForm)}>
                <div className='input-group'>
                <label>Prenom <span className='obligatoire'>*</span>
                    <input type="text" placeholder='Prenom' name='prenom'
                     value={FirstUpperCase(userInfo.prenom)}
                      {...register('prenom', {
                        onChange: (e) => {
                             handleInput(e)
                        },
                      required:true,minLength:3},
                    )}
                    
                    />
                  {errors.prenom && errors.prenom?.type === "required" && (
                            <span className="error-msg">Le prénom est obligatoire.</span>
                        )}
                  {errors.prenom && errors.prenom?.type === "minLength" && (
                        <span className="error-msg">Le caractère minimum est trois (3).</span>
                  )}
            
                </label>
                <label>Nom <span className='obligatoire'>*</span>
                    <input type='text' placeholder='Nom' name="nom"
                     value={FirstUpperCase(userInfo.nom)}
                     {...register('nom', {
                       onChange: (e) => {
                            handleInput(e)
                       },
                     required:true,minLength:3},
                   )}
                    />
                  {errors.nom && errors.nom?.type === "required" && (
                       <span className="error-msg">Le nom est obligatoire.</span>
                   )}
                  {errors.nom && errors.nom?.type === "minLength" && (
                        <span className="error-msg">Le caractère minimum est trois (3).</span>
                  )}
                </label>
                <label > Email <span className='obligatoire'>*</span>
                    <input type="email" placeholder='Email' name='email'
                    value={userInfo.email}
                     {...register('email', {
                        onChange: (e) => {
                             handleInput(e)
                        },
                      required:true,
                      pattern: /\S+@\S+\.\S+/g,
                    },
                    )}
                    />
                 {errors.email && errors.email?.type === "required" && (
                    <span className="error-msg">L'email est obligatoire.</span>
                 )}
                 {errors.email && errors.email?.type === "pattern" && (
                    <span className="error-msg">Le Format du numéro de email est example@example.com.</span>
                )}
                </label>
                </div>
                <div className='input-group'>
                <label>Téléphone <span className='obligatoire'>*</span>
                    <input type='tel'placeholder='ex:620 000 000'
                     name = "telephone" value={userInfo.telephone}
                     {...register("telephone", {
                     onChange: (e) => {
                        handleInput(e);
                     },
                     required: true,
        
                     minLength: 9,
                    pattern: /6[0-9]{8}$/g,
                   })} 
                  />
                 {errors.telephone && errors.telephone?.type === "required" && (
                     <span className="error-msg">Ce champ est obligatoire.</span>
                 )}
                 {errors.telephone && errors.telephone?.type === "minLength" && (
                     <span className="error-msg">Le numéro de téléphone ne peut pas être inférieur à 9 caractères.</span>
                 )}
                 {errors.telephone && errors.telephone?.type === "pattern" && (
                      <span className="error-msg">Le Format du numéro de téléphone est 620 000 000.</span>
                 )}
                </label>
                
                 <label>Localité <span className='obligatoire'>*</span>
                     <select value={userInfo.prefecture_id} name= "prefecture_id"
                       {...register('prefecture_id', {
                        onChange: (e) => {
                            handleInput(e)
                        },
                        validate: (value) => value != "" 
                        },)}
                      >
                        <option value="">-</option>
                       {localites?.length > 0 && 
                        localites.map((localite) => {
                           return(<option value={localite.prefecture_id} key={localite._id}>{localite.nom}</option>)
                        })
                       }
                     </select>
                     {errors.prefecture_id && (
                             <span className="error-msg">La localité est obligatoire.</span>
                     )}
                  </label>
                  <label>Centre de traitement <span className='obligatoire'>*</span>
                     <select value={userInfo.centre_id} name= "centre_id"
                       {...register('centre_id', {
                        onChange: (e) => {
                            handleInput(e)
                        },
                        validate: (value) => value != "" 
                        },)}
                      >
                        <option value="">-</option>
                        { centres && centres.filter((p) => p.localite_id == userInfo.prefecture_id).map((centre) => (
                             <option value={centre.centre_id} key={centre._id}>{centre.nom}</option>
                        ))}
                     </select>
                     {errors.centre_id && (
                        <span className="error-msg">Le centre de traitement est obligatoire.</span>
                     )}
                  </label>
                <label>Rôle <span className='obligatoire'>*</span>
                    <select name='role'
                     value={userInfo.role}
                     {...register('role', {
                        onChange: (e) => {
                            handleInput(e)
                        },
                        validate: (value) => value != "" 
                        },)}
                    >
                        <option value="" key="0">-</option>
                        <option value="management" key="management">Management</option>
                        <option value="service client" key="service client">Service client</option>
                        <option value="superviseur" key="superviseur">Superviseur</option>
                        <option value="edition" key="edition">Edition</option>
                        <option value="service impression" key="service impression">Service impression</option>
                    </select>
                    {errors.role && (
                        <span className="error-msg">Le rôle est obligatoire.</span>
                     )}
                </label>
                </div>
                <Erreurs validation={erreurs} />
                <div>
                    <button>Valider</button>
                </div>
            </form>
           
        </div>
    </div>
  )
}

export default NewUser