import React, { useState } from "react";
import Erreurs from "../../components/erreurs/Erreurs";
import logo from "../../assets/logo-dsd.png";

import { useForm } from "react-hook-form";
import { Helmet } from "react-helmet-async";
import { Link, useNavigate } from "react-router-dom";
import { useContext } from "react";
import { UserContext } from "../../utils/context/contex";
import MainClass from "../../utils/classes/MainClass";

const Login = () => {
  const [userInfo , setUserInfo ] = useState({
    username:'',
    password:''
  });
  const { register, handleSubmit, formState: { errors } } = useForm({});
 
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { user,setUser } = useContext(UserContext);
  
  const api = new MainClass();
  const [ erreurs,setErreurs ] = useState(null);
  
  //Avoir les Inputs de l'utilisateur
   const handleInput = (e) => {
    setUserInfo({...userInfo,[e.target.name]:e.target.value});
   }
   const submitForm = async (e) => {
      setIsLoading(true); setErreurs([]);
      try {
      const data = await api.apiData('post','/user/login',userInfo);
      setIsLoading(false);
      if( data?.status !== 200 ){
        setErreurs(data.errors);
      }
      else {
        setUser(data.user.user);
        api.setToken(data.user.user.token);
        //navigate('/dashboard');
        window.location.href = "/dashboard"
      }
    }catch(e){
        // const errors = api.handleError(e);
        console.log(e);
    }
   
   }

  
  return (
    <div className="login-page">
      <Helmet>
        <title>DSDGUINEE - Connexion</title>
      </Helmet>
    
      <div className="head">
        <div className="header-1">
          <p>République de Guinée</p>
          <p><span>Travail-</span><span>Justice-</span><span>Solidarité</span></p>
        </div>
      
      </div>
      <div className="form">
        <div className="logo">
          <div className="image">
            <img src={logo} alt="" />
          </div>
        </div>
        <form onSubmit={handleSubmit(submitForm)}>
        <h3>Connectez-vous à votre compte.</h3>
        <div>
            <label>Email ou Téléphone
               <input type="text" name="username" value={userInfo.username} placeholder="Nom d'utilisateur" 
                {...register('username', {
                  onChange: (e) => {
                      handleInput(e)
                  },
                  required:true,min:2
                 })}
                 autoComplete ="off"
               />
                {errors.username && errors.username?.type === "required" && (
                  <span className="error-msg">Le nom d'utilisateur est obligatoire.</span>
               )}
               {errors.username && errors.username?.type === "min" && (
                  <span className="error-msg">Le nom d'utilisateur doit contenir au moin deux (2) caractères .</span>
               )}
            </label>
         </div> 
         <div>  
            <label className="password">
               <input type="password" name="password"
                value={userInfo.password} 
                  placeholder="Mot de passe"
                 {...register('password', {
                  onChange: (e) => {
                      handleInput(e)
                  },
                  required:true,min:6
                 })}  
                 autoComplete ="off"
               />
              {errors.password && errors.password?.type === "required" && (
                  <span className="error-msg">Le mot de passe est obligatoire.</span>
               )}
               {errors.password && errors.password?.type === "min" && (
                  <span className="error-msg">Le mot de passe doit contenir au moin six (6) caractères .</span>
               )}
            </label>
          </div>  
            <Erreurs validation={erreurs}/>
            <button type="submit" disabled={isLoading}> {isLoading ? "...Patientez":"Se connecter"}</button>
        </form>
      
      </div>
      {/* <div className="motifs">
        <img src={star} alt="" className="motif star" />
        <img src={ellipse1} alt="" className="motif ellipse1" />
        <img src={ellipse2} alt="" className="motif ellipse2" />
        <img src={polygon1} alt="" className="motif polygon1" />
        <img src={line1} alt="" className="motif line1" />
        <img src={rectangle1} alt="" className="motif rectangle1" />
      </div> */}
      <div className="footer">
        <p>DSD-GUINEE &copy;2022. Tous droits réservés</p>
      </div>
    </div>
  );
};

export default Login;
