import React, { useState, useContext, useMemo,useEffect } from "react";
import BarChart from "../../components/BarChart.js/BarChart";
import BarChart2 from "../../components/BarChart.js/BarChar2";

import { AiOutlineCheckCircle } from "react-icons/ai";
import { GiSandsOfTime } from "react-icons/gi";
import { isEmpty } from "../../utils/helper/functions";
import Spinner from "../../components/Spinner/Spinner";

import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import LineChart from "../../components/LineChart/LineChart";
import ChangerMdpModal from "../../components/ChangerMdpModal/ChangerMdpModal";
import MainClass from "../../utils/classes/MainClass";
import { UserContext } from "../../utils/context/contex";
import moment from "moment";
import "moment/locale/fr";

// import PieChart from "../../components/PieChart/PieChart";
const Dashboard = () => {
  moment.suppressDeprecationWarnings = true;
  const [isLoading, setIsLoading] = useState(false);
  const [ graphdata,setGraphData ] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [filterMonth,setFilterMonth ] = useState("");
  const [ erreurs,setErreurs ] = useState([]);
  const [ graph2 ,setGraph2 ] = useState([]);
  const [ validerByMonth,setValiderByMonth ] = useState([]);
  const [ validerByMonthInitial,setValiderByMonthInitial ] = useState([]);
  const [ initieByMonth,setInitieByMonth ] = useState([]);
  const [ initieByMonthInitial,setInitieByMonthInitial ] = useState([]);
  const [filterInitie,setFilterInitie ] = useState("");
  const [ dossierGraph,setDossierGraph ] = useState({
    valide: 0,
    initie: 0 
  });

  const { user } = useContext(UserContext);
 
  const api = new MainClass();
  const barCharDocValides = {
    labels: validerByMonth.map((data) => data.mois),
    datasets: [
      {
        label: "Totaux Dossiers Validés",
        data: validerByMonth?.map((data) => data.total),
        backgroundColor: [
          "rgba(75,192,192,1)",
          "#ecf0f1",
          "#50AF95",
          "#f3ba2f",
          "#2a71d0",
        ],
        borderColor: "black",
        borderWidth: 2,
      },
    ],
  };
  const barCharDocInities = {
    labels: initieByMonth.map((data) => data.mois),
    datasets: [
      {
        label: "Totaux Dossiers Initiés",
        data: initieByMonth?.map((data) => data.total),
        backgroundColor: [
          "rgba(75,192,192,1)",
          "#ecf0f1",
          "#50AF95",
          "#f3ba2f",
          "#2a71d0",
        ],
        borderColor: "black",
        borderWidth: 2,
      },
    ],
  };
  const barCharRendu = {
    labels:  graphdata?.graph?.graph1.map((data) => data.mois),
    datasets: [
      {
        label: "Cart grise Rendue",
        data:  graphdata?.graph?.graph1.map((data) => data.total),
        backgroundColor: [
          "rgba(75,192,192,1)",
          "#ecf0f1",
          "#50AF95",
          "#f3ba2f",
          "#2a71d0",
        ],
        borderColor: "black",
        borderWidth: 2,
      },
    ],
  };
  const graphImpression = async () => {
      setErreurs([]);setIsLoading(true);
      const { status , statistique,errors } = await api.apiData('get','/dashboard/graphs');
     
   
      setIsLoading(false);setErreurs([]);
      if( status === 200 ){
        setGraphData(statistique);
        setGraph2(statistique?.graph?.graph2)
      }else setErreurs(errors)
  }
  const graphImmatriculation = async () => {
    const { status,errors , statusCount,traitements } = await api.apiData("get","/dossier/statusDossier");
  
    setIsLoading(false);setErreurs([]);
   
    if( status === 200 ){
       if( statusCount.length > 0 ){
        let valider;let initie = 0;
        statusCount.forEach((elem,index) => {
           if( elem._id.status == "validé" ){
              valider = elem.totalStatus;
           }
           else if( elem._id.status === "initié" ){
             initie = elem.totalStatus;
           }
         });
        
         setDossierGraph({
          valide: valider,
          initie: initie
         });
         if( traitements ){
          let arrValidee = [];
          let arrAttente = [];
          traitements.forEach((traitement) => {
            if( traitement._id.status === "validé" ){
              const months = traitement._id.formattedDate.split("-");
              const monthString = moment(months[0]).format("MMMM");
              const month = monthString.charAt(0).toUpperCase() + monthString.slice(1);
              arrValidee = [{
                mois: month,
                total: traitement.total,
                moisNumber: months[0]
              },...arrValidee];
            }
            else if(traitement._id.status === "initié" ){
              const months = traitement._id.formattedDate.split("-");
              const monthString = moment(months[0]).format("MMMM");
              const month = monthString.charAt(0).toUpperCase() + monthString.slice(1);
              arrAttente = [{
                mois: month,
                total: traitement.total,
                moisNumber: months[0]
              },...arrAttente];
            }
          
          });
      
          setValiderByMonth(arrValidee)
          setValiderByMonthInitial(arrValidee);
          setInitieByMonthInitial(arrAttente);
          setInitieByMonth(arrAttente);
         }
        
       }
       else{
        setErreurs(errors)
       }
    }
  }
  //console.log(validerByMonth)
  const getGraphData =  () => {
  
    if( user?.role?.toLowerCase() === "superviseur" || user?.role?.toLowerCase() === "service client" ||  user?.role?.toLowerCase() === "management" || user?.role?.toLowerCase() === 'edition'){
      graphImpression();
     }
     else if(user?.role?.toLowerCase() === "admin" ){
          graphImmatriculation();
          graphImpression();
     }
     
    }
  useEffect(() => {
    getGraphData();
  }, [user]);

  const handleChangeMonth = (e) => {
      setFilterMonth(e.target.value);
      if(e.target.value !== ""){
        setValiderByMonth(validerByMonthInitial.filter((p) => p.moisNumber === e.target.value))
      }else{
        setValiderByMonth(validerByMonthInitial);
      }
    // else setGraph2(graphdata.graph.graph2); 
  
  }
  const handleFilterInitie = (e) => {
    setFilterInitie(e.target.value);
    //console.log(e.target.value)
   
    if(e.target.value !== ""){
      setInitieByMonth(initieByMonthInitial.filter((p) => p.moisNumber == e.target.value));
      //console.log(initieByMonthInitial.filter((p) => p.moisNumber === e.target.value))
    }
    else{
      setInitieByMonth(initieByMonthInitial);
    }
    
  // else setGraph2(graphdata.graph.graph2); 

}

  return (
    <div className="dashboard page">
      <Helmet>
        <title>Tableau de bord</title>
      </Helmet>
      {isLoading && <Spinner />}
      <main>
        <div>
          <ChangerMdpModal
            isOpen={isOpen}
            setIsOpen={setIsOpen}
          />
        </div>
        <div className="greetings">
          <h3>
            Bienvenue,{ user?.prenom}
          </h3>
          <span>Voici un resumé des dernières activités.</span>
        </div>
        <div className="stats">
          {(user?.role?.toLowerCase() === "admin" || user?.role?.toLowerCase() === "management" || user?.role?.toLowerCase() === "service impression") &&
            <>
            <Link to="#">
              <div className="stat-box">
                <div className="box-left">
                  <span className="value">{ graphdata?.card?.total }</span>
                  <span className="sub-title valider">Impression</span>
                  <p className="text">Total</p>
                </div>
              </div>
            </Link>
            <Link to="#" state={{ title: "Attente", filter_id: 0 }}>
              <div className="stat-box">
                <div className="box-left">
                  <span className="value">{graphdata?.card?.non_randue}</span>
                  <span className="sub-title attente">Impression</span>
                  <p className="text">Non Rendue</p>
                </div>
                <div className="box-right">
                  <GiSandsOfTime />
                </div>
              </div>
            </Link>
            <Link to="#" state={{ title: "Validé", filter_id: 1 }}>
              <div className="stat-box">
                <div className="box-left">
                  <span className="value">{graphdata?.card?.rendue}</span>
                  <span className="sub-title valider">Impression</span>
                  <p className="text">Rendue</p>
                </div>
                <div className="box-right">
                  <AiOutlineCheckCircle />
                </div>
              </div>
            </Link></>
          }
          {(user?.role?.toLowerCase() === "admin" || user?.role?.toLowerCase() === "service client" || user?.role?.toLowerCase() === "superviseur" || user?.role?.toLowerCase() === 'edition') &&
            <>
              <Link to="/dossier/liste" state={{ title: "initié" }}>
                <div className="stat-box">
                  <div className="box-left">
                    <span className="value">{ graphdata?.dossier?.initie }</span>
                    <span className="sub-title attente">Immatrication</span>
                    <p className="text">En Attente</p>
                  </div>
                  <div className="box-right">
                    <GiSandsOfTime />
                  </div>
                </div>
              </Link>
              <Link to="/dossier/liste" state={{ title: "validé" }}>
                <div className="stat-box">
                  <div className="box-left">
                    <span className="value">{ graphdata?.dossier?.valide }</span>
                    <span className="sub-title valider">Immatrication</span>
                    <p className="text">Validée</p>
                  </div>
                  <div className="box-right">
                    <GiSandsOfTime />
                  </div>
                </div>
              </Link>
            </>

          }
          {/* <Link
            to="#"
            state={{ title: "Rejeté", filter_id: 2 }}
          >
            <div className="stat-box">
              <div className="box-left">
                <span className="value">90</span>
                <p className="text">Réclamation</p>
              </div>
              <div className="box-right">
                <AiOutlineCloseCircle />
              </div>
            </div>
          </Link> */}
        </div>

        <div className="charts">
          <h2>Statistiques</h2>
          <div className="graphes">
            <div className="bar-chart">
              <div className="head">
                <h4>Dossier Traité</h4>
                <div className="month">
                  <select value={filterMonth} name="mois" onChange={(e) => handleChangeMonth(e)} >
                    <option value="">-</option>
                    <option value="01">Janvier</option>
                    <option value="02">Février</option>
                    <option value="03">Mars</option>
                    <option value="04">Avril</option>
                    <option value="05">Mai</option>
                    <option value="06">Juin</option>
                    <option value="07">Juillet</option>
                    <option value="08">Août</option>
                    <option value="09">Septembre</option>
                    <option value="10">Octobre</option>
                    <option value="11">Novembre</option>
                    <option value="12">Décembre</option>
                  </select>
                </div>
              </div>
              <BarChart chartData={barCharDocValides} />
            </div>
            <div className="bar-chart">
              <div className="head">
                <h4>Dossier En attente</h4>
                <div className="month">
                  <select value={filterInitie} name="mois" onChange={(e) => handleFilterInitie(e)}>
                    <option value="">-</option>
                    <option value="01">Janvier</option>
                    <option value="02">Février</option>
                    <option value="03">Mars</option>
                    <option value="04">Avril</option>
                    <option value="05">Mai</option>
                    <option value="06">Juin</option>
                    <option value="07">Juillet</option>
                    <option value="08">Août</option>
                    <option value="09">Septembre</option>
                    <option value="10">Octobre</option>
                    <option value="11">Novembre</option>
                    <option value="12">Décembre</option>
                  </select>
                </div>
              </div>
              <BarChart2 chartData={barCharDocInities} />
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Dashboard;
