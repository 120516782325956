import React, { useState } from 'react'
import "./ListeDossier.scss"
import ListDossier from '../../components/ListDossier.js/ListDossier'
import Spinner from '../../components/Spinner/Spinner'

function ListeDossier() {
  const [ isLoading,setIsLoading ] = useState(false)  
  return (
    <div className='page listedossier'>
        {isLoading && <Spinner /> } 
        <div className='listedossier__header'>
           <h3>Liste des Dossiers</h3>
        </div>
        <div className='listedossier__body'>
            <ListDossier setIsLoading={setIsLoading}/>
        </div>
    </div>
  )
}

export default ListeDossier