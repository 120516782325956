import { useState,useEffect,useContext, useRef } from "react";
import Spinner from "../../components/Spinner/Spinner";
import { Helmet } from 'react-helmet-async';
import { isEmpty } from "../../utils/helper/functions";
import { useForm } from "react-hook-form";
import Erreurs from "../../components/erreurs/Erreurs";
import MainClass from "../../utils/classes/MainClass";
import Swal from "sweetalert2";
import toast from "react-hot-toast";
const Impression = () => {
  const [ isLoading,setIsLoading ] = useState(false);
  const [erreurs,setErreurs ] = useState([]);
  // const inputref = useRef();
  const [ nouvelleImprresion,setnouvelleImpression ] = useState({
    numeroImmatriculation:''
  })
  const numberPattern = /^[a-zA-z]{1,3}(-){1}[0-9].{0,3}(\s|-){1}([a-zA-z]|[0-9]){1,3}|\s[0-9]{1,3}$/;
  const { register, handleSubmit, reset,formState: { errors } } = useForm({
    defaultValues:{
      nouvelleImprresion
    }
});
 const api = new MainClass();
  const handleInput = (e) => {
    var valeur = e.target.value;
    if( typeof valeur === 'string' )
      valeur = valeur.toUpperCase();
    setnouvelleImpression({...nouvelleImprresion,[e.target.name]: valeur});
  }
  const NouvelleImpressionAdd = async () => {
       setErreurs([]);setIsLoading(true);
       const data  = new FormData();
       data.append("numeroImmatriculation",nouvelleImprresion.numeroImmatriculation);
       const response = await api.apiData('post','/impression/nouvelle',data);
       setIsLoading(false); 
       if( response.status === 200 ){
          toast.success("Ajout d'impression effectuée avec succès.");
          document.querySelector('.numeroImmatriculation').focus();
       }
       else{
           setErreurs(response.errors);
          
       }
  }
  const AjoutNumero = async () => {
    setErreurs([]);
 
    const data  = new FormData();
    data.append("numeroImmatriculation",nouvelleImprresion.numeroImmatriculation);
    setIsLoading(true);
    const isExistResponse = await api.apiData('post','/impression/isPrinted',data); 
    setIsLoading(false);
    if(isExistResponse.status === 200){
       if( isExistResponse.isExist ){
        Swal.fire({
          title: "Nouvelle Impression?",
          text: "Ce numéro est déjà imprimé.Voulez-vous continuer?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#2a9d8f",
          cancelButtonColor: "#e63946",
          confirmButtonText: "Valider",
          cancelButtonText: "Annuler",
        }).then((result) => {
          if (result.isConfirmed) {
            NouvelleImpressionAdd();
            setnouvelleImpression({...nouvelleImprresion,numeroImmatriculation:''})
          }
        })
       }else {
        NouvelleImpressionAdd();
        setnouvelleImpression({...nouvelleImprresion,numeroImmatriculation:''});
       }
    }else {
      setErreurs(isExistResponse.errors);
    
    }

    // console.log(response)
    // nouvelleImprresion;
  }

  return (
    <div className="immatriculation-page page">
      <Helmet>
        <title>Nouvelle Impression</title>
      </Helmet>
      {isLoading && <Spinner />}
      <h2>Nouvelle Impression</h2>
      <form onSubmit={handleSubmit(AjoutNumero)}>
        <div className="input-group">
           <label>Numero d'immatriculation
              <input type="text" placeholder="ex:A-000-01"
               value={nouvelleImprresion.numeroImmatriculation}
               className="numeroImmatriculation"
               name="numeroImmatriculation"
               {...register('numeroImmatriculation', {
                onChange: (e) => {
                    handleInput(e)
                },
                required:true,
                 pattern:{
                     value: numberPattern,
                     message:"Respectez le format d'immatriculation (ex:A-0993-02)"
                 }
                },
               )}
      
              />
              {errors.numeroImmatriculation && errors.numeroImmatriculation?.type === "required" && (
                  <span className="error-msg">Le Numéro d'immatriculation est obligatoire.</span>
               )}
                {errors.numeroImmatriculation && (
                   <span className="error-msg">{errors.numeroImmatriculation.message}</span>
                 )}
           </label>
        </div>
        <Erreurs validation={erreurs}/>
        <div style={{ marginTop:'8px'}}>
          <button className="primary" type="submit">Valider</button>  
        </div>
      
      </form>
    </div>
  );
};

export default Impression;
