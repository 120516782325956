import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import Spinner from '../../components/Spinner/Spinner';
import Box from "@mui/material/Box";
import { DataGridPremium, GridToolbar, frFR } from "@mui/x-data-grid-premium";
import { ImpressionData } from '../../utils/data/fakeData';
import { useNavigate } from 'react-router-dom';
import "./ListImpression.css";
import MainClass from '../../utils/classes/MainClass';
import moment from 'moment/moment';
import toast from 'react-hot-toast';
function ListImpression() {
  const [isLoading,setIsLoading ] = useState(false);  
  const [ data,setData ] = useState(ImpressionData);
  const [ impData ,setImpData ] = useState([]);
  const navigate = useNavigate();
  const api = new MainClass();
  const [ filter,setFilter ] = useState({
    date1: '',
    date2: ''
  });
  const[ pageState,setPageState ] = useState({
    data:[],
    total: 0,
    page: 1,
    pageSize: 10,
    totalPages: 0, 
  })
  const retrait = (id) => {
     navigate(`/retrait/${id}`)
  }
  const options = (params) => {
    const id = params.row.id;
    return (
        <div className="options">
             <button onClick={() => retrait(params.row._id)}>Voir Détails</button>                               
        </div>
    );
  }
  const columnDefs = [
    { headerName: "_id", field: "_id", minWidth: 0,hide:true},
    { headerName: "N°Ord.", field: "id", minWidth: 80 },
    { headerName: "Numéro", field: "numeroImmatriculation",  minWidth: 120 , flex: 1, },
    { headerName: "Nom et prénom client", field: "fullname", minWidth: 215 },
    { headerName: "Téléphone", field: "telephone", minWidth: 120},
    { headerName: "Email", field: "email",minWidth: 150 },
    { headerName: "Addresse", field: "addresse",minWidth:180 },
    // { headerName: "Modele", field: "modele",  flex: 1 },
    // { headerName: "Affectation", field: "ministere",  flex: 1 },
    { headerName: "Status", field: "status",   minWidth: 90},
    { headerName: "Date Imp.", field: "date",   minWidth: 110},
    { headerName: "Date Remise.", field: "dateRemise",   minWidth: 110},
    { headerName: "Options", field: "options", minWidth:150,renderCell: options ,  sortable: false,}, 
  ];
  const getListImpression = async () => {
    try{
      setIsLoading(true);
        const response = await api.apiData('get',`/impression/list?currentPage=${pageState.page}&pageSize=${pageState.pageSize}`);
        if( response?.status === 200 ){
          let immatArray = [];
          if( response?.listImpression?.length > 0 ){
            response.listImpression.map((impression,index) => {
              let dateRemise = '';
              if( typeof impression?.client?.dateRemise !== 'undefined' )
                dateRemise = moment(impression.client.dateRemise).format('dddd,DD MMMM YYYY');

              let immobject = {
                id:response.startIndex + 1 + index,
                _id:impression._id,
                status:impression.status.charAt(0).toUpperCase() + impression.status.slice(1).toLowerCase(),
                numeroImmatriculation:impression.numeroImmatriculation,
                date: moment(impression.createdAt).format('DD/MM/YYYY'),
                fullname : impression?.client?.prenom && impression?.client?.prenom  + " "+ impression?.client?.nom,
                telephone:impression?.client?.telephone,
                email: impression?.client?.email,
                addresse: impression?.client?.addresse,
                prenom: impression?.client?.prenom ,
                nom: impression?.client?.nom,
                dateRemise: dateRemise ? dateRemise.charAt(0).toUpperCase() + dateRemise.slice(1).toLowerCase():''
              };
              immatArray.push(immobject); 
            });
           setPageState((prev) => ({...prev,data:immatArray,total:response.totalRecord,}))
          }
        }else if(data.status === 500 ){
          toast.error("Vous n'êtes pas connecté")
           navigate('/');
        } 
      setIsLoading(false);
     }
    catch(e){
      console.log(e)
    }
  }
  useEffect(() => {
    getListImpression();
  },[pageState.page,pageState.pageSize]);
  const getListImpressionWithParams = async (date1,date2) => {
      let data = []; 
      if( date1 && !date2){
         data = await api.apiData('get',`/impression/list?date1=${date1}`);
      }else if( !date1 && date2){
        data = await api.apiData('get',`/impression/list?date2=${date2}`);
      }else if( date1 && date2){
        data = await api.apiData('get',`/impression/list?date1=${date1}&date2=${date2}`);
      }
      setIsLoading(true);
      if( data?.status === 200 ){
        let immatArray = [];
        if( data?.listImpression?.length > 0 ){
          data.listImpression.map((impression,index) => {
            let dateRemise = '';
            if( typeof impression?.client?.dateRemise !== 'undefined' )
              dateRemise = moment(impression.client.dateRemise).format('dddd,DD MMMM YYYY');
           
            let immobject = {
              id:index + 1,
              _id:impression._id,
              status:impression.status.charAt(0).toUpperCase() + impression.status.slice(1).toLowerCase(),
              numeroImmatriculation:impression.numeroImmatriculation,
              date: moment(impression.createdAt).format('DD/MM/YYYY'),
              fullname : impression?.client?.prenom && impression?.client?.prenom  + " "+ impression?.client?.nom,
              telephone:impression?.client?.telephone,
              email: impression?.client?.email,
              addresse: impression?.client?.addresse,
              prenom: impression?.client?.prenom ,
              nom: impression?.client?.nom,
              dateRemise: dateRemise ? dateRemise.charAt(0).toUpperCase() + dateRemise.slice(1).toLowerCase():''
            };
            immatArray.push(immobject); 
          });
        }
        setImpData(immatArray);
      }else if(data.status === 500 ){
        toast.error("Vous n'êtes pas connecté")
         navigate('/');
      } 
    setIsLoading(false);
  }
  const handleDate =  (e) => {
    if( e.target.name === 'date1' ){
      if(moment(filter?.date2).isAfter(e.target.value) || !filter?.date2){
        setFilter({...filter,[e.target.name]:e.target.value});
        getListImpressionWithParams(e.target.value,filter.date2);
      }
    }else if( e.target.name === 'date2'){
      if(moment(filter?.date1).isBefore(e.target.value) || !filter?.date1){
        getListImpressionWithParams(filter.date1,e.target.value);
        setFilter({...filter,[e.target.name]:e.target.value});
      }
    }
   console.log(moment(filter?.date2).isAfter(e.target.value))
  }

  return (
    <div className='impression page'>
     {isLoading && <Spinner />}
      <Helmet>
         <title>Liste des impressions et rétrait</title>
      </Helmet>
      <h2>Liste des impressions et rétraits</h2>
      <div className="input-group">
            <div>
               <label>Debut
                  <input type='date' value={filter.date1} name='date1' onChange={(e) => handleDate(e)}/> 
              </label>
            </div>
            <div>
               <label>Fin
                   <input type='date' value={filter.date2} name='date2' onChange={(e) => handleDate(e)} />
               </label>
            </div>
             <div></div>
      </div>
      {pageState.data.length > 0 ?
        <div className="array">
                <Box sx={{ height: 579, width: "100%" }}>
                  <DataGridPremium
                       sx={{ borderRadius: 0 }}
                       density="compact"
                       components={{ Toolbar: GridToolbar}}
                       checkboxSelection
                       rows={pageState.data}
                       columns={columnDefs}
                       autoPageSize
                       pagination
                       paginationMode='server'
                       disableSelectionOnClick
                       rowCount={pageState.total}
                       page={pageState.page - 1 }
                       slots={{ toolbar: GridToolbar }}
                       onPageSizeChange={(newpageSize, details ) => {
                        // Maybe save into state
                        setPageState((prev) => ({...prev,pageSize:newpageSize}))
                      }}
                      onPageChange={(newPage) => {
                        setPageState(prev => ({ ...prev, page: newPage + 1 }))
                      }}
                    />
                </Box>
        </div>:<p>Aucune Impression effectuée</p>
      }
    </div>
  )
}

export default ListImpression