import React,{useState,useEffect,useMemo, createContext} from "react";
import "./styles/App.scss";
import { Routes, Route, useParams, useNavigate, useLocation } from "react-router-dom";
import Login from "./pages/Login/Login";
import Navbar from "./components/Navbar/Navbar";
import Dashboard from "./pages/Dashboard/Dashboard";

import { LicenseInfo } from '@mui/x-license-pro';
import Impression from "./pages/Impression/Impression";
import Retrait from "./pages/Retrait/Retrait";
import ListImpression from "./pages/ListImpression/ListImpression";
import Utilisateur from "./pages/Utilisateur/Utilisateur";
import NewUser from "./pages/Utilisateur/NewUser/NewUser";
import { LocaliteContext, UserContext,ElementContext } from "./utils/context/contex";
import MainClass from "./utils/classes/MainClass";
import IsConnected from "./components/IsConnected/IsConnect";
import toast, { Toaster } from "react-hot-toast";
import { isObjectEmpty } from "./utils/helper/functions";
import ListImprimeur from "./pages/ListImprimeur/ListImprimeur";
import ImprimeurDetails from "./pages/ImprimeurDetails/ImprimeurDetails";
import InititPassWord from "./pages/InititPassWord/InititPassWord";
import NouveauDossier from "./pages/NouveauDossier/NouveauDossier";
import DossierDetails from "./pages/DossierDetails/DossierDetails";
import ListeDossier from "./pages/ListeDossier/ListeDossier";
import DossierDetailsGroup from "./pages/DossierDetailsGroup/DossierDetailsGroup";

function App() { 
  //Datagrid license key
  LicenseInfo.setLicenseKey(process.env.REACT_APP_DATAGRID_KEY);
  const [isLogging ,setIsLoading] = useState(false);
  const [ user,setUser ] = useState({});
  const [localites,setLocalites ] = useState({});
  const [elements,setElements ] = useState();
  const location = useLocation();
  const api = new MainClass();
  const navigate = useNavigate();
  const getCurrentUser = async () => {
       try{
       
        if( localStorage.getItem('token') && isObjectEmpty (user)){
          const data = await api.apiData('get','/user/getCurrentUser'); 
          if( data.status === 200 ){
            setUser(data.user)
          }else if(data.status === 500 ){
            toast.error("Vous n'êtes pas connecté.")
            navigate('/');
          } 
          else {
            if( !localStorage.getItem('token') && location.pathname.indexOf("initPass") === -1 ){
              
              navigate('/');toast.error("Vous n'êtes pas connecté!")
            
            }
          }
        }
        else {
          api.removeToken();
        }
      
    }
    catch(err) {
      if( !localStorage.getItem('token') ){
        navigate('/');
        toast.error("Vous n'êtes pas connecté!");
      }
    }
  }
  const getLocalites = async () => {
    const data = await api.apiData('get','/localite/getlocalites');
    const _data = await api.apiData("get","/Elements/getElements") ;
 
    if(data.status === 200){
       setLocalites(data.Prefecture);
    }
    if( _data.status === 200 ){
      setElements( _data.element);
     
    }
  }
  useEffect(() => {
  getLocalites();
  getCurrentUser();

  return() => {
     if(localStorage.getItem('token')){
        setIsLoading(true);
        setIsLoading(false);
     }
     else if(location.pathname.indexOf("initPass") === -1 ){
             navigate('/')
     }
    }
  },[]);

  // console.log(location.pathname.indexOf("utilisateur/initPass"))
  return (
    <div className="App">
      <Toaster position="top-right" reverseOrder={false}  toastOptions={{ className: "toast", duration: 4000 }}/>

      <UserContext.Provider value={{ user: user, setUser: setUser }}>
        <LocaliteContext.Provider value={{ localites:localites,setLocalite:setLocalites }}>
          <ElementContext.Provider value={{  elements }}>
            {localStorage.getItem('token') && <Navbar isLogging={isLogging} />  }
              <Routes>
                <Route path="/" element={<Login />} />
                <Route path="/dashboard" element={<Dashboard />}/>
                <Route path="/nouvelle-impression" element={<Impression />} />
              
                <Route path="/list-impression" element={<ListImpression />} />
                <Route path="/liste-imprimeur" element={<ListImprimeur />} />
                <Route path="/retrait/:id" element={<Retrait />} />
                <Route path="/imprimeur/details/:id" element={<ImprimeurDetails />} />
                <Route path="/utilisateur">
                    <Route path="" element={<Utilisateur />} />
                    <Route path="new" element={<NewUser />} />
                    <Route path="initPass/:id" element={<InititPassWord />} />
                </Route>
              
                <Route path="/dossier">
                    <Route path="nouveau" element={<NouveauDossier />}>Nouveau</Route>
                    <Route path="details/:id" element={<DossierDetails />} />
                    <Route path="details/group" element={<DossierDetailsGroup />} />
                    <Route path="liste"  element={<ListeDossier />} />
                </Route>
              
              </Routes>
         </ElementContext.Provider>
        </LocaliteContext.Provider>
         
      </UserContext.Provider>   
    </div>
  );
}

export default App;