import React, { useEffect, useRef, useState } from 'react';
import "./Utilisateur.css";
import Box from "@mui/material/Box";
import { DataGridPremium, GridToolbar, frFR } from "@mui/x-data-grid-premium";
import { fakeUsers } from "../../utils/data/fakeData.js";
import { useNavigate } from 'react-router-dom';
import MainClass from '../../utils/classes/MainClass';
import Spinner from '../../components/Spinner/Spinner';
import { FirstUpperCase } from '../../utils/helper/functions';
import moment from 'moment/moment';
import Swal from 'sweetalert2';
import { set } from 'react-hook-form';
import Erreurs from '../../components/erreurs/Erreurs';
import UpdateUtilisateurModal from '../../components/UpdateUtilisateurModal/UpdateUtilisateurModal';
import { useGridApiRef } from '@mui/x-data-grid';
function Utilisateur() {
    const [ userData,setUserData ] = useState([]);
    const [ erreurs,setErreurs ] = useState([]);
    const [ isLoading,setIsLoading ] = useState(false);
    const [ isOpenModal,setIsOpenModal ] = useState(false);
    const [ selectedUser , setSelectedUser ] = useState('');
    const navigate = useNavigate();
    const api = new MainClass();
    const [ isUpdate,setUpdate ] = useState(true);
    const tabref = useGridApiRef();
    const options = (params) => {
        const id = params.row.id;
        // console.log(params.row)
        return (
            <div className='options'>
                {parseInt(params.row.status) === 1 ?
               <button className='delete' type="button" onClick={() => { deleteHandle(params.row)}}>
                   Désactiver
               </button>
               
               :<button className='activate' onClick={() => { deleteHandle(params.row)}}>
                  Activer
                </button>
                } 
                <button className='primary-yellow' onClick={() => {setSelectedUser(params.row);setIsOpenModal(true)}}>Modifier</button>    
            </div>
        )
    }
    const [columnDefs] = useState([
      {
        headerName: "_id",
        field: "_id",
        flex:1,
        minWidth: 40 ,
        hide:true  
      },
        {
            headerName: "N°Ord",
            field: "ord",
  
            minWidth: 50   
        },

        {
            headerName: "Nom",
            field: "nom",
            flex : 1,
            minWidth:140
          },
          {
            headerName: "Prenom",
            field: "prenom",
            flex:1,
            minWidth:150
          },
          {
            headerName: "Téléphone",
            field: "telephone",

            minWidth:130
          },
          {
            headerName: "Role",
            field: "role",
            flex:1,
            minWidth:140
          },
          {
            headerName: "Localité",
            field: "localite",
            flex:1,
            minWidth:150
          },
          {
            headerName: "Centre de T.",
            field: "centre",
            flex:1,
            minWidth:150
          },
          {
            headerName: "Date Ins.",
            field: "date",
            flex:1,
            minWidth:110
          },
      
          { headerName: "Options", field:'options', minWidth:430,renderCell: options },
    
    ]); 
   const getAllUsers = async () => {
    setIsLoading(true)
    const { status,users,errors } = await api.apiData('get','/user/getAllUsers');
 
    if( status === 200 ){
   
      let ord = 0;
      setUserData(users?.map((user) => {
         ord = ord + 1;
       
         return{
          ord: ord,
          id: user._id,
          nom: user.nom,
          prenom: user.prenom,
          telephone: user.telephone,
          email: user.email ,
          role: FirstUpperCase(user.role),
          localite: user.localiteCentre ?user.localiteCentre.nom:'Non fournie',
          date: moment(user.createdAt).format("DD/MMM/YY"),
          status: user.status,
          centre: user.centreUser ? user.centreUser.nom: "Non fournie",
          centre_id: user.centreUser ? user.centreUser.centre_id : "",
          prefecture_id : user.localiteCentre ? user.localiteCentre.prefecture_id:''
         }
      }));
    }
  
    setIsLoading(false)
   }
   const deleteHandle = async (data) => {
    Swal.fire({
      title: "Êtes-vous sûr?",
      text: `${parseInt(data.status) === 1 ?'Voulez-Vous Desactivé cet utilisateur?':'Voulez-Vous Activé cet utilisateur?'}`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: `Oui, ${parseInt(data.status) === 1 ? 'desactiver!':'activer!'}`,
      cancelButtonText: "Annuler",
    }).then((result) => {
      if (result.isConfirmed) {
        setErreurs([]);setIsLoading(true);
        var formdata = new FormData();
        formdata.append("_id",data.id);
        api.apiData("post","/user/activeDesactiveUser",formdata).then((response) => {
          setIsLoading(false);
          console.log(response)
          if( response.status === 200 )
            setUpdate(!isUpdate);
          else {
            setErreurs(response.errors);
          }  
        });
      }
    });
   }
  useEffect(() => {
    getAllUsers();
  },[isUpdate])
  return (
    <div className='utilisateur page'>
       {isLoading && <Spinner />}
       <UpdateUtilisateurModal 

           isOpen={isOpenModal} 
           setIsOpen={setIsOpenModal} 
           setIsLoading = {setIsLoading} 
           isUpdate = {isUpdate}
           setUpdate = {setUpdate}
           data={selectedUser}/>
        <div className='head'>
            <div className='title'>Utilisateurs</div>
            <div className='btn'>
                <button className='secondary' onClick={() => navigate("/utilisateur/new")}>Nouveau</button>
            </div>
        </div>
        <div className='body'>
           <Erreurs validation={erreurs} />
           <div className="array">
              <Box sx={{ height: 579, width: "100%" }}>
               <DataGridPremium
                 tabref={tabref}
                 sx={{ borderRadius: 0 }}
                 density="compact"
                 components={{ Toolbar: GridToolbar}}
                 rows={userData}
                 columns={columnDefs}
                 autoPageSize
                 pagination
                 disableSelectionOnClick
                 localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
            />
              </Box>  
           </div> 
        </div>    
    </div>
  )
}

export default Utilisateur