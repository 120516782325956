import React, { useEffect, useState } from 'react'
import Spinner from '../../components/Spinner/Spinner';
import { Helmet } from 'react-helmet-async';
import Box from "@mui/material/Box";
import { DataGridPremium, GridToolbar, frFR,} from "@mui/x-data-grid-premium";
import MainClass from '../../utils/classes/MainClass';
import { useParams } from 'react-router-dom';
import Erreurs from '../../components/erreurs/Erreurs';
import "./ImprimeurDetails.css"
import moment from 'moment';
function ImprimeurDetails() {
  const [ isLoading, setIsLoading ] = useState(false);  
  const [ data , setData ] = useState([]);
  const [ erreurs, setErreurs ] = useState([]);
  const [ ImprimeurInfo,setImprimeurInfo ] = useState('');
  const api = new MainClass();
  const { id } = useParams();
  const columnDefs = [
    { headerName: "_id", field: "_id", minWidth: 0,hide:true},
    { headerName: "N°Ord.", field: "id", maxWidth: 70 },
    { headerName: "Prénom et Nom", field: "prenom_nom",  minWidth: 350 , hide:true},
    // { headerName: "Téléphone", field: "telephone", minWidth: 150},
    { headerName: "Immatriculation", field: "numeroImmatriculation",minWidth: 210 },
    { headerName: "Date Imp.", field: "dateImp",minWidth:180 },
    { headerName: "Date Liv.", field: "dateLiv",minWidth:180 },

  ];


  const getPrintedCard = async (id) => {
    setIsLoading(true);
    const { status , PrintedList, errors } = await api.apiData("get",`/impression/getDetailImpressionByImprimeur/${id}`);
    setIsLoading(false);
    if( status === 200 ){
      if( PrintedList.length > 0){
        let dta = [];
        setImprimeurInfo({
            prenom_nom: PrintedList[0].prenom + " " + PrintedList[0].nom,
        })
     
        setData(PrintedList[0].listImpression.map((printed,index) => {
            return{
                 id: index + 1,
                _id: printed._id,
                prenom_nom: PrintedList[0].prenom + " " + PrintedList[0].nom,
                telephone: PrintedList[0].telephone,
                numeroImmatriculation:  printed.numeroImmatriculation,
                dateImp: moment(printed.createdAt).format("Do MMMM YYYY"),
                dateLiv : printed?.client?.dateRemise ? moment(printed?.client?.dateRemise).format("Do MMMM YYYY"):"Non Disponible"
            }
        }));
      }
    }
    else setErreurs(erreurs);
  }
//   console.log(data)
  useEffect(() => {
    getPrintedCard(id);
  },[id])
  return (
    <div className='page'>
       <div className='header' style={{ marginBottom:'2em'}}>
            {isLoading && <Spinner />}
            <Helmet>
                <title>Cartes Grises Imprimées</title>
            </Helmet>
            <h3>Cartes Grises Imprimées</h3>
        </div>
        <div className='body'>
          <Erreurs validation={erreurs} />  
          {data.length > 0 ?
                <div className="array">
                        <Box sx={{ height: 579, width:"90%" }}>
                        <DataGridPremium
                            sx={{ borderRadius: 0 }}
                            density="compact"
                            // components={{ Toolbar: GridToolbar}}
                            rows={data}
                            columns={columnDefs}
                            
                            autoPageSize
                            pagination
                            disableSelectionOnClick
                            localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                            initialState={{
                                rowGrouping:{
                                    model:['prenom_nom']
                                }
                            }}
                            />
                        </Box>
                </div>:<p>Aucune Impression effectuée</p>
            } 
        </div>
    </div>
  )
}

export default ImprimeurDetails