import React, { useEffect, useState } from 'react'
import { Box } from '@mui/material'
import { DataGridPremium ,GridToolbar, frFR} from '@mui/x-data-grid-premium';
import moment from "moment";
import "moment/locale/fr";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import MainClass from '../../utils/classes/MainClass';
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import toast from 'react-hot-toast';
import { objecttoFormData } from '../../utils/helper/functions';
function ListDossier({setIsLoading}) {
  const [dossiers , setDossiers ] = useState([]);
  const api = new MainClass();

  const { state } = useLocation();
  
  const [ selectedRows,setSelectedRows ] = useState([]);
  const [contextMenu, setContextMenu] = useState(null);
  const [filterModel, setFilterModel] = useState({ items: [] });
  const [sortModel, setSortModel] = useState([]);
  const[ pageState,setPageState ] = useState({
    data:[],
    total: 0,
    page: 1,
    pageSize: 10,
    totalPages: 0, 
  })
  const navigate = useNavigate();
  const handleContextMenu = (event) => {
    event.preventDefault();
    // setSelectedRow(Number(event.currentTarget.getAttribute("data-id")));
    setContextMenu(
      contextMenu === null
        ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 }
        : null
    );
  };
  const handleClose = () => {
    setContextMenu(null);
  };
  const columns = [{
      field: "ordre",
      headerName: "N°",
      maxWidth: 50
   },
   {
    field: "fullNameClient",
    headerName: "Nom  Client",
    minWidth: 180
   },
   {
    field: "telephoneClient",
    headerName: "Telephone Client",
    minWidth: 160
   },
  //  {
  //   field: "emailClient",
  //   headerName: "Email Client",
  //   minWidth: 160
  //  },
   {
    field: "fullNameDeposant",
    headerName: "Nom  Deposant",
    minWidth: 110
   },
   {
    field: "telephoneDeposant",
    headerName: "telephoneDeposant",
    minWidth: 110
   },
   {
    field: "localite",
    headerName: "Localite",
    minWidth: 110
   },
   {
    field: "centre",
    headerName: "Centre de T.",
    minWidth: 110
   },
   {
    field: "numero",
    headerName: "Numéro",
    minWidth: 150
   },
   {
    field: "status",
    headerName: "status",
    minWidth: 20
   },
   {
    field: "created_at",
    headerName: "Date d'initiation",
    minWidth: 90
   },
   {
    field: "date_updated",
    headerName: "Date de Validation",
    minWidth: 90
   },
   {
    field: "options",
    headerName: "Options",
    sortable: false,

    flex: 1,
    minWidth: 130,
    renderCell: (params) => {
      const id = params.id;
      return (
        <div className="options">
          <Link to={`/dossier/details/${id}`}>
            <button>Voir Dossier</button>
          </Link>
        </div>
      );
    },
 }
];
const getListDossier = async () => {
  setIsLoading(true);
   let url = `/dossier/getdossiers?currentPage=${pageState.page}&pageSize=${pageState.pageSize}`;
   if( state?.title ){
     url = `/dossier/getdossiers?currentPage=${pageState.page}&pageSize=${pageState.pageSize}&filterState=${state?.title}`
   }
   let { status , dossiers , totalPages,total,startIndex} = await api.apiData("get",url);
  setIsLoading(false);
  //console.log(dossiers)
   if( status === 200 ){
    if( dossiers.length > 0 ){
      dossiers = dossiers[0].data;
      // if( state?.title ){
      //     dossiers = dossiers.filter((p) => p.status.toLowerCase() == state?.title.toLowerCase() );
      //     total = dossiers.length;
      //     totalPages = Math.ceil(dossiers.length / pageState.pageSize );
      //   }
    
    // if( dossiers.length > 0 ){
       dossiers = dossiers.map((dossier,index) => {
       return{
          id:dossier._id,
          ordre: startIndex + index + 1,
          nomClient:dossier.client.nom,
          prenomClient: dossier.client.prenom,
          fullNameClient: dossier.client.prenom + " " + dossier.client.nom,
          telephoneClient: dossier.client.telephone,
          emailClient: dossier.client.email,
          fullNameDeposant: dossier.deposant.prenom + " " + dossier.deposant.nom,
          nomDeposant:dossier.deposant.nom,
          prenomDeposant: dossier.deposant.prenom,
          telephoneDeposant: dossier.deposant.telephone,
          numero: dossier.numero,
          chassis:dossier.vehicule.chassis,
          created_at: moment(dossier.created_at).format("DD/MMM/YY"),
          date_updated:moment(dossier.updated_at).format("DD/MMM/YY"),
          localite: dossier?.centreT?.prefecture?dossier?.centreT?.prefecture:"Non Fournie",
          centre: dossier?.centreT?.centre ? dossier?.centreT?.centre: "Non Fournie",
          status:dossier.status.charAt(0).toUpperCase() + dossier.status.slice(1)
      }
     });
    // }
    }
     //setIsLoading(false);
     setPageState((prev) => ({...prev,data:dossiers,total:total,totalPages:totalPages}))
   }
}
const filterData = async (filterOption) => {
  if(filterOption.items.length > 0 ){
     setIsLoading(true);
     var formdata = objecttoFormData(filterOption);
     let url = `/dossier/getdossiers?currentPage=${pageState.page}&pageSize=${pageState.pageSize}`;
     if( state?.title ){
       url = `/dossier/getdossiers?currentPage=${pageState.page}&pageSize=${pageState.pageSize}&filterState=${state?.title}`
     }
     const response = await api.apiData("post",url,formdata);
    setIsLoading(false);
    if (response.status === 200 ){
      let  dossiers = [];
    
       if( response.dossiers.length > 0 ){
         dossiers = response.dossiers[0].data.map((dossier,index) => {
           return{
            id:dossier._id,
            ordre: response.startIndex + index + 1,
            nomClient:dossier.client.nom,
            prenomClient: dossier.client.prenom,
            fullNameClient: dossier.client.prenom + " " + dossier.client.nom,
            telephoneClient: dossier.client.telephone,
            emailClient: dossier.client.email,
            fullNameDeposant: dossier.deposant.prenom + " " + dossier.deposant.nom,
            nomDeposant:dossier.deposant.nom,
            prenomDeposant: dossier.deposant.prenom,
            telephoneDeposant: dossier.deposant.telephone,
            numero: dossier.numero,
            chassis:dossier.vehicule.chassis,
            created_at: moment(dossier.created_at).format("DD/MMM/YY"),
            date_updated:moment(dossier.updated_at).format("DD/MMM/YY"),
            localite: dossier?.centreT?.prefecture?dossier?.centreT?.prefecture:"Non Fournie",
            centre: dossier?.centreT?.centre ? dossier?.centreT?.centre: "Non Fournie",
            status:dossier.status.charAt(0).toUpperCase() + dossier.status.slice(1)
           }
         });
       }
       setPageState((prev) => ({...prev,data: dossiers,total:response?.total,totalPages:response?.totalPages}));

    }
    // setIsLoading(false)
    
  }
};
useEffect(() => {
  if( filterModel?.items?.length === 0 ){
    getListDossier();
  }
  else  {
    const _timer = setTimeout(() => {
      filterData(filterModel);
    },[1000])
    return () => {
      clearTimeout(_timer);
    }

  }

},[pageState.page,pageState.pageSize,filterModel]);

const validationGroupe = () => {
  // console.log(selectedRows)
  if( selectedRows.length === 0 ){
    toast.error('Pas de Dossier selectionné.')
  }
  else{
     navigate("/dossier/details/group",{ state:{selectedDossier : selectedRows }}) 
  }
   setContextMenu(null);
}


  return (
    <div className='liste'>
       
       <Box sx={{ height: "100vh", width: "100%" }}>
             <DataGridPremium 
               sx={{ borderRadius: 0 }}
               density="compact"
               components={{ Toolbar: GridToolbar}}
               checkboxSelection
               rows={pageState.data}
               columns={columns}
               autoPageSize
               pagination
               paginationMode='server'
               disableSelectionOnClick
               rowCount={pageState.total}
               page={pageState.page - 1 }
               slots={{ toolbar: GridToolbar }}
               filterMode='server'
               onFilterModelChange={(newFilterModel) => {
                setFilterModel(newFilterModel);
              }}
              onSortModelChange={setSortModel}
              onPageSizeChange={(newpageSize, details ) => {
                // Maybe save into state
                setPageState((prev) => ({...prev,pageSize:newpageSize}))
              }}
              onPageChange={(newPage) => {
                setPageState(prev => ({ ...prev, page: newPage + 1 }))
              }}
               isRowSelectable={(params) => params.row.status === "Initié"}
               onSelectionModelChange={(ids) => {
                const selectedIDs = new Set(ids);
                const selectedRowData = pageState.data.filter((row) =>
                  selectedIDs.has(row.id.toString())
                );
                setSelectedRows(selectedRowData);
              }}
               componentsProps={{
                row: {
                  onContextMenu: handleContextMenu,
                  style: { cursor: "context-menu" }
                }
              }}
               localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
             />
           </Box>  
           <Menu
          open={contextMenu !== null}
          onClose={handleClose}
          anchorReference="anchorPosition"
          anchorPosition={
            contextMenu !== null
              ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
              : undefined
          }
        componentsProps={{
          root: {
            onContextMenu: (e) => {
              e.preventDefault();
              handleClose();
            }
          }
        }}
      >
        <MenuItem onClick={validationGroupe}>Valider les dossiers selectionnés</MenuItem>
        {/* <MenuItem onClick={changeDiagnosis}></MenuItem>
        <MenuItem onClick={changeDoctor}></MenuItem>
        <MenuItem onClick={cancleDiagnosis}></MenuItem> */}
       </Menu>
    </div>
  )
}

export default ListDossier