import React, { useContext, useEffect, useState } from 'react'
import "./Retrait.css";
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import MainClass from '../../utils/classes/MainClass';
import Spinner from '../../components/Spinner/Spinner';
import { FirstUpperCase } from '../../utils/helper/functions';
import { objecttoFormData } from '../../utils/helper/functions';
import Erreurs from '../../components/erreurs/Erreurs';
import toast from 'react-hot-toast';
import { UserContext } from '../../utils/context/contex';
function Retrait() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const [ Immdata,setImmData ] = useState({
    id: '',
    numeroImmatriculation: '',
    status: '',
    client: {
      nom: '',
      prenom: '',
      telephone: '',
      email: '',
      addresse: '',

    }

  });
  const api = new MainClass();
 const [ isLoading ,setIsLoading ] = useState(false); 
 const [ erreurs ,setErreurs ] = useState([]);

  const getImpressionByID = async (id) => {
    try{
       const response =  await api.apiData('get',`impression/getPrintedByID/${id}`);
       let clientData = {
          nom: '',
          prenom: '',
          telephone: '',
          email: '',
          addresse: '',
          operateur:{
            nom:'',
            prenom:'',
            telephone: '',
          }
       }
       if( response.status === 200 ){
      
         if( response.data.client ){
           clientData.nom = response.data.client.nom;
           clientData.prenom = response.data.client.prenom;
           clientData.email = response.data.client.email;
           clientData.telephone = response.data.client.telephone;
           clientData.addresse = response.data.client.addresse;
          clientData.operateur.nom = response.data.client.owner.nom;
          clientData.operateur.prenom = response.data.client.owner.prenom;
          clientData.operateur.telephone = response.data.client.owner.telephone;
          clientData.operateur.email = response.data.client.owner.email;
         }
          setImmData({...Immdata,
            id: response.data._id,
            numeroImmatriculation: response.data.numeroImmatriculation,
            status: response.data.status,
            client: clientData
          })
       }
       else {
        setErreurs(response.data.errors);
       }
    }
    catch(e) {
        
        setErreurs(e);
        console.log(api.handleError(e))
    }
  } 
  useEffect(() => {
    getImpressionByID(id);
    document.getElementsByName("prenom")[0].focus(); 
     
          document.querySelectorAll('input').forEach((input) => {
            if(user && Immdata){
             if( (user?.role?.toLowerCase() === 'management' || user.role?.toLowerCase() === "service impression"))
               input.setAttribute("readOnly",true);
            }
          })
  },[id]);

  const handleInput = (e) => {
    if(e.target.name === "telephone" ){
      const re = /^[0-9\b]+$/;
      if( (re.test(e.target.value) || e.target.value === '') && e.target.value.length <= 9 ){
        setImmData({...Immdata,client:{...Immdata.client,[e.target.name]:FirstUpperCase(e.target.value)}});
      }
      return;
    }
    else if( e.target.name === "email" ){
      setImmData({...Immdata,client:{...Immdata.client,[e.target.name]:e.target.value}});
      return;
    }
    setImmData({...Immdata,client:{...Immdata.client,[e.target.name]:FirstUpperCase(e.target.value)}});
   
  }
  const submitForm = async (e) => {
    if( (Immdata.status === 'imprimé' && (user?.role?.toLowerCase() !== 'admin' && user?.role?.toLowerCase() !== 'management' && user?.role?.toLowerCase() !== 'service impression')) ){
     setErreurs([]);setIsLoading(true);
     var data = new FormData();
     data.append('id',Immdata.id);
     data.append('nom',Immdata.client.nom);
     data.append('prenom',Immdata.client.prenom);
     data.append('telephone',Immdata.client.telephone);
     data.append('email',Immdata.client.email);
     data.append('addresse',Immdata.client.addresse);
     try{
         const respnse = await api.apiData('post','/impression/saveClient',data);
         if(respnse.status === 200 ){
           toast.success("La carte a été remise au client avec succes.");
           navigate("/list-impression");
         }
         else {
          setErreurs(respnse.errors);
         }
     }
     catch(e){
      console.log(e)
      setErreurs(e);
     }
     setIsLoading(false);
    }
  }
  const { register, handleSubmit, reset,formState: { errors } }  = useForm({
    defaultValues:{Immdata}
  });
  return (
    <div className='retrait page'>
      {isLoading && <Spinner /> }
      <div className='header'>
        <h2>Rétrait de carte grise</h2>
        <p>Information du client</p>
      </div>
      <hr />
       
       <form onSubmit={handleSubmit(submitForm)}>
         <div className='input-group'>
              <label>Numéro d'immatriculation <span className='obligatoire'>*</span>
                <input type='text' placeholder="Example:RC-numero-serie" name='numeroImmatriculation' 
                value = { Immdata.numeroImmatriculation } 
                readOnly
                  
                /> 
              </label>
          
             <label>Prénom <span className='obligatoire'>*</span>
                <input type='text' placeholder="Prénom"
                 name='prenom' value={Immdata.client.prenom}
                 {...register('prenom', {
                     onChange: (e) => {
                          handleInput(e)
                     },
                 required:true,minLength:2 },
                 )}
                /> 
                 {errors.prenom && errors.prenom?.type === "required" && (
                     <span className="error-msg">Le prenom du client est obligatoire.</span>
                 )}
                 {errors.prenom && errors.prenom?.type === "minLength" && (
                    <span className="error-msg">Le caractère minimum est deux (2).</span>
                 )}
              </label>
             <label>Nom <span className='obligatoire'>*</span>
                <input type='text' placeholder="Nom"
                 name='nom' value={Immdata.client.nom}
                 {...register('nom', {
                    onChange: (e) => {
                         handleInput(e)
                    },
                  required:true,minLength:2 },
                 )}
                
                /> 
                 {errors.nom && errors.nom?.type === "required" && (
                     <span className="error-msg">Le nom du client est obligatoire.</span>
                 )}
                 {errors.nom && errors.nom?.type === "minLength" && (
                    <span className="error-msg">Le caractère minimum est deux (2).</span>
                 )}
              </label>
         </div>
         <div className='input-group'>
             <label>Téléphone <span className='obligatoire'>*</span>
                <input type='tel' placeholder='ex:620 000 000'
                name = "telephone" value={Immdata.client.telephone}
                {...register("telephone", {
                  onChange: (e) => {
                    handleInput(e);
                  },
                  required: true,
        
                  minLength: 9,
                  pattern: /6[0-9]{8}$/g,
                })} 
                />
                 {errors.telephone && errors.telephone?.type === "required" && (
                     <span className="error-msg">Ce champ est obligatoire.</span>
                 )}
                 {errors.telephone && errors.telephone?.type === "minLength" && (
                     <span className="error-msg">Le numéro de téléphone ne peut pas être inférieur à 9 caractères.</span>
                 )}
                 {errors.telephone && errors.telephone?.type === "pattern" && (
                      <span className="error-msg">Le Format du numéro de téléphone est 620 000 000.</span>
                 )}
             </label>  
            
             <label>Email 
                <input type='email' placeholder='ex:example@example.com' 
                 name='email' value={Immdata.client.email}
                 {...register("email", {
                  onChange: (e) => {
                    handleInput(e);
                  },
                  required: false,
                  pattern: /\S+@\S+\.\S+/g,
                })} 
                />
                {errors.email && errors.email?.type === "pattern" && (
                   <span className="error-msg">Le Format du numéro de email est example@example.com.</span>
                )}
              </label>
              <label>Addresse 
                <input type='text' placeholder='addresse' 
                 name='addresse' value={Immdata.client.addresse}
                 {...register("addresse", {
                  onChange: (e) => {
                    handleInput(e);
                  },
                  required: false,
                  minLength:3
                })} 
                />
                {errors.addresse && errors.addresse?.type === "minLength" && (
                   <span className="error-msg">Le caractère minimum pour l'addresse est trois (3).</span>
                )}
              </label>       
            
         </div> 
         
         <Erreurs validation={erreurs} />
         {(Immdata.status === 'imprimé' && (user?.role?.toLowerCase() !== 'admin' && user?.role?.toLowerCase() !== 'management' && user?.role?.toLowerCase() !== 'service impression')) &&
          <div style={{ marginTop:'8px'}}>
           <button className="primary" type='sumbit'>Valider</button>  
          </div> 
          }
       </form>
       {Immdata?.client?.operateur?.telephone &&
        <div className='operateur'>
           <div className='header'>
             <p>Information du service client qui a Livré</p>
           </div>
           <hr />
           <div className='input-group'>
            {user?.telephone !== Immdata?.client?.operateur.telephone ? 
               <>
                <div>
                  <label>Prénom et Nom
                      <span>{Immdata?.client?.operateur.prenom + " " + Immdata?.client?.operateur.nom}</span>
                  </label>
                </div>
                <div>
                  <label>Téléphone
                    <span>{Immdata?.client?.operateur.telephone }</span>
                  </label>
                </div>
                <div>
                  <label>Email
                    <span>{Immdata?.client?.operateur.email }</span>
                  </label>
                </div>
               </>
               :<>
                 <div className='self'>
                   <label>Vous Même</label>
                 </div>
               </>
            }
             
           </div> 
        </div>}
    </div>
  )
}

export default Retrait